import React, { useState, useEffect } from 'react';
import GalleryUpload from '../../../slideshow-page/GalleryUpload';
import { useBrands } from '../../../../../context/brands';

export const BrandGallery = () => {
	const [imageData, setImageData] = useState('');
	const { setImageDataUrl } = useBrands();

	console.log(imageData);
	const handleImageUpload = (image) => {
		console.log('Image uploaded:', image); // Log the image received
		setImageData(image); // Update state
	};

	useEffect(() => {
		console.log('Updated imageData:', imageData); // Log the updated imageData
		setImageDataUrl(imageData);
		console.log(imageData);
	}, [imageData]);

	const imageProps = {
		type: 'brand',
		slideshow: false,
		navigate: '/admin/shoes/brands',
		multiple: false,
	};
	return (
		<div className={`form-group upload-image upload-image-blog-wrapper`}>
			<div className="">
				{imageData && <img src={imageData} alt="Uploaded brand logo" width={100} style={{ border: 'dashed 1px gray' }} />}

				<GalleryUpload props={imageProps} onImageUpload={handleImageUpload} />
			</div>
		</div>
	);
};
