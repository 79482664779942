import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';

const options = [5, 10, 20, 50, 100];
const Costs = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [form, setForm] = useState({ productFee: '' }); // Define form state
	const [formError, setFormError] = useState({ productFee: false });

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	const getReadData = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
		};

		try {
			const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getCosts}`, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${token}` },
			});

			if (response.data) {
				// Assuming response.data contains the product fee information

				const feeCost = response.data.costs.find((cost) => cost.type === 'fee');
				console.log(feeCost);
				// If the feeCost is found, set productFee to the amount
				let productFee;
				if (feeCost) {
					productFee = feeCost.amount;
				}
				console.log(productFee);
				setForm((prevForm) => ({ ...prevForm, productFee }));
				console.log(productFee);
			}
			console.log(response);
			setReadDataLoading(false);
		} catch (err) {
			console.error('Error fetching data:', err);
			setReadDataLoading(false);
		}
	}, [user]);

	useEffect(() => {
		getReadData();
		console.log(readData);
	}, [getReadData]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setForm((prevForm) => ({ ...prevForm, [name]: value }));
	};

	const validateForm = () => {
		let isValid = true;
		if (!form.productFee) {
			isValid = false;
			setFormError((prevError) => ({ ...prevError, productFee: true }));
		} else {
			setFormError((prevError) => ({ ...prevError, productFee: false }));
		}
		return isValid;
	};

	const onSubmit = useCallback(async () => {
		if (!validateForm()) return;
		const { token } = user; // Ensure `user` is defined in your context or props
		const costsCreatePayload = {
			type: 'fee',
			amount: form.productFee,
		};
		console.log(costsCreatePayload);
		await axios
			.put(`${SERVER_URL}/${AXIOS_API_CALL.addCost}`, costsCreatePayload, {
				withCredentials: false,
				headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					notification.success({ message: 'Cost added!' });
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			})
			.catch((err) => {
				notification.error({ message: 'Failed to add costs.' });
				console.error('Error updating costs:', err);
			})
			.finally(() => {});
	}, [form, user]);

	return (
		<>
			<h2 className="page-title">Costs</h2>
			<Section className="section__wrapper section__costs">
				<form name="product-info" className="form" onSubmit={validateForm}>
					<div className="form-group">
						<label className="label" htmlFor="productFee">
							<b>Variation Fee:</b>
						</label>
						<input value={form.productFee || ''} onChange={handleChange} name="productFee" placeholder="Variation Fee" type="number" id="productFee" className={`input ${formError.productFee ? 'error' : ''}`} /> <span className="inputHelper">%</span>
						<p data-cy="" className={`error__onblur ${formError.productFee ? 'error' : ''}`}>
							* This field is required!
						</p>
					</div>
					<button onClick={() => onSubmit(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`)} className="btn btn-primary" type="button">
						<span className="text">Add Cost</span>
					</button>
				</form>
			</Section>
		</>
	);
};

export default Costs;
