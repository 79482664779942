import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data, handleCheckedRows, handleDisabledButton, handleToggle } = props;

	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS
	const { deleteActions, groceryPermissions, getGroceryPermissions, updateActions } = props;


	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);

	// HANDLE CHECKED ALL
	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setIsCheck(data.map((li) => li._id));
		if (isCheckAll) {
			setIsCheck([]);
		}
	};

	// HANDLE CHECKED CURRENT
	const handleClick = (e) => {
		const { id, checked } = e.target;
		setIsCheckAll(true);
		setIsCheck([...isCheck, id]);
		if (!checked) {
			setIsCheck(isCheck.filter((item) => item !== id));
		}
	};

	// UNTOGGLE CHECKED TOP
	useEffect(() => {
		if (isCheck.length < 1) {
			setIsCheckAll(false);
		}
	}, [isCheck, setIsCheckAll]);

	useEffect(() => {
		handleDisabledButton(isCheckAll);
	}, [isCheckAll, handleDisabledButton]);

	useEffect(() => {
		handleCheckedRows(isCheck);
    console.log(handleCheckedRows)
	}, [handleCheckedRows, isCheck]);

	function getType(type) {
		switch (type) {
			case 'simple_brand':
				return 'Simple';
			case 'variable_brand':
				return 'Variable';
			default:
				console.warn('Default of: getType function!');
		}
	}
	function getCheckType(type, payload) {
		switch (type) {
			case 'simple_brand':
				return (
					<div>
						<span className="mr-1">
							<span>${!!Number(payload.SalePrice) && !isNaN(Number(payload.SalePrice)) ? <strike>{payload.RegularPrice?.toFixed(2)}</strike> : payload.RegularPrice?.toFixed(2)}</span>
						</span>
						<span>
							<span>{!!Number(payload.SalePrice) && !isNaN(Number(payload.SalePrice)) && <b className="c-red">${payload.SalePrice?.toFixed(2)}</b>}</span>
						</span>
					</div>
				);
			case 'variable_brand':
				return (
					<div>
						<span>
							<span className="d-block mb-1 mr-1"></span>
							<span>{payload.priceRange}</span>
						</span>
					</div>
				);
			default:
				console.warn('Default of: getType function!');
		}
	}

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						<th>
							<Checkbox type="checkbox" name="select-all-customers" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
						</th>
						<th>Name</th>
						<th>Icon</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td data-cy={`checkbox-item-brands-${idx}`}>
									{/* {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateCustomers].includes(key.name)) && ( */}
									<div>
										<Checkbox key={item._id} type="checkbox" name={item._id} id={item._id} handleClick={handleClick} isChecked={isCheck.includes(item._id)} />
									</div>
									{/* )} */}
								</td>
								<td>{item.name}</td>
								<td>
									<img src={item.selectedIcon} alt="logo" width={100} />
								</td>
								<td>
									{/* {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateBrands, PERMISSIONS.deleteBrands, PERMISSIONS.readBrands].includes(key.name)) && ( */}
									<Popover
										className="tbody-actions"
										placement="left"
										trigger="click"
										content={() => (
											<div className="actions" data-cy="actions-popover-brands">
												{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateBrands) && (
													<button className="btn btn-actions" type="button" data-cy="actions-delete-brand" onClick={() => handleToggle({ name: 'update', state: updateActions, data: item })}>
														<span className="icon d-flex">
															<img alt="EDIT" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
														</span>
														<span className="text">EDIT</span>
													</button>
												)}
												{getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteBrands) && (
													<button className="btn btn-actions" type="button" data-cy="actions-delete-brand" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
														<span className="icon">
															<img alt="Delete" src="/assets/icons/delete.svg" />
														</span>
														<span className="text">
															<strong>DELETE</strong>
														</span>
													</button>
												)}
											</div>
										)}
									>
										<button className="btn" type="button" data-cy="actions-toggle-brands">
											<span className="icon">
												<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
											</span>
										</button>
									</Popover>
									{/* )} */}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
