import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { notification } from 'antd';
import UnderConstruction from '../block-list-page/UnderConstruction';

const options = [5, 10, 20, 50, 100];

const Discounts = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setReadDataRefetch((prevState) => !prevState);
		console.log(search);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setReadDataRefetch((prevState) => !prevState);
	};

	// PAGE
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	// FILTER STATUS
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setReadDataRefetch((prevState) => !prevState);
	};

	// FILTERS
	const [disabledButton, setDisabledButton] = useState(false);
	const handleDisabledButton = (status) => {
		setDisabledButton(status);
	};

	// Get selected rows id one or more
	const [checkedRow, setCheckedRow] = useState([]);
	const handleCheckedRows = (data) => {
		setCheckedRow(data);
	};

	const getReadData = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
			searchTerm: search,
		};

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.discountsProducts}`, { ...payload }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					console.log('Response data:', res.data);
					return res;
				})
				.catch((err) => console.error(err))
				.finally(
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200)
				);
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search, setCurrentPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [limitPage, setCurrentPage]);

	// FUNCTION
	function handleToggle(props) {
		const { name, state, data } = props;

		switch (name) {
			case 'create':
				state.onToggle(true);
				break;
			case 'update':
				state.onToggle(true);
				break;

			default:
				console.warn('Default of: handleToggle function');
		}
	}

	async function onSubmit(props) {
		const { event, name, data } = props;
		event.preventDefault();
		const { token } = user;
	}

	// CREATE
	const createModalFormRef = useRef(null);
	const [toggleCreate, setToggleCreate] = useState(false);
	const [createForm, setCreateForm] = useState({}); // data

	// UPDATE
	const updateModalFormRef = useRef(null);
	const [toggleUpdate, setToggleUpdate] = useState(false);
	const [updateFormId, setUpdateFormId] = useState({ id: '' });
	const [updateForm, setUpdateForm] = useState({}); // data

	// create
	const createProps = {
		active: toggleCreate,
		onToggle: setToggleCreate,
		formRef: createModalFormRef,
		data: createForm,
		onData: setCreateForm,
	};

	// update
	const updateProps = {
		active: toggleUpdate,
		onToggle: setToggleUpdate,
		formRef: updateModalFormRef,
		data: updateForm,
		onData: setUpdateForm,
	};

	// PROPS
	const tableProps = {
		handleDisabledButton: handleDisabledButton,
		handleCheckedRows: handleCheckedRows,
	};

	const tableActionsProps = {
		handleToggle: handleToggle,

		updateActions: updateProps,

		location: location,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: 1,
		totalCount: 1,
	};

	console.log(readData);

	return (
		<>
			<h2 className="page-title">Discounts</h2>
			<UnderConstruction/>
		</>
	);
};

export default Discounts;
