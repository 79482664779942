import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data } = props;
	// PAGINATION
	const { pagination } = props;

	const formatDate = (isoString) => {
		const date = new Date(isoString);
		return date.toLocaleString(); // You can customize this format
	};

	console.log(data);

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>ColorWay</th>
						<th>Product</th>
						<th>Number</th>
						<th>Status</th>
						<th>Total Payout</th>
						<th>Buyers</th>
						<th>Created At</th>
						<th>Ship by</th>
						<th>Penalty at</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td>{item.ID}</td>
								<td>{item.name}</td>

								<td>{item.colorway}</td>
								<td>{item.product}</td>
								<td>{item.orderNumber}</td>
								<td>{item.status}</td>
								<td>{item.totalPrice}</td>
								<td> {item.buyer}</td>
								<td> {formatDate(item.createdAt)}</td>
								<td> {item.shipBy}</td>
								<td> {item.penaltyAt}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
