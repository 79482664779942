import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
// Tabs component
import { Attributes } from './attributes/Attributes';

export const ProductOptions = (props) => {
  const { form } = useProducts();
  const { className } = props;

  return (
		<Wrapper className="">
				<Attributes />	
		</Wrapper>
	);
};
