import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import DropdownSelect from '../DropdownSelect';

export const CreateParentCategory = ({ createProps, createFormRef, handleOnSubmit, imageData, handleOnRemoveUpload, handleOnUpload, createFormError, createForm, handleOnChange, handleOnBlur, attributes, toggleCreate, handleOnSelect, setToggleCreate, createBtnLoader, disabledCreate, setSelected, isFeatured, setIsFeatured }) => {
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right form-category" title="Create Category">
      <form name="create" ref={createFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: createFormRef })}>
        <div className={`form-group upload-image`}>
          {imageData.blob ? (
            <div className="avatar">
              <div className="image-wrapper">
                <img src={imageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
              </div>

              <button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'create' })}>
                <span className="icon" data-cy="image-placeholder"></span>
                <span className="text">Remove Image</span>
              </button>
            </div>
          ) : (
            <div className="upload">
              <div className="upload-wrapper d-flex gap-x-2">
                <div>
                  <span className="text">
                    <div className="text-center">
                      <p className="mb-0">No Image</p>
                      <p className="mb-0">selected</p>
                    </div>
                  </span>
                  <input
                    type="file"
                    name="createImage"
                    id="uploadFile"
                    className="input-upload"
                    onChange={(event) =>
                      handleOnUpload({
                        name: event.target.name,
                        img: event.target.files[0]
                      })
                    }
                  />
                </div>

                <div className="validation-image">
                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
                    * You can only upload JPG/PNG file!
                  </p>

                  <p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
                    * Image must smaller than 2MB!
                  </p>
                </div>
              </div>
              <label htmlFor="uploadFile" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
                <span className="icon">
                  <img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
                </span>
                <span className="text">Upload Image</span>
              </label>
            </div>
          )}
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createName">
            <b className="required">Name:</b>
          </label>

          <input name="createName" id="createName" value={createForm.createName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy="name-create-input" className={`input ${createFormError.createName || createFormError.uniqueName ? 'input-error' : ''}`} />

          <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueName ? '' : createFormError.createName ? 'error' : ''}`}>
            * This field is required!
          </p>
          <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueName ? 'error' : ''}`}>
            * This category name already exists!
          </p>
        </div>
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createSlug">
            <b className="required">Slug:</b>
          </label>

          <input name="createSlug" id="createSlug" value={createForm.createSlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-create-input" className={`input ${createFormError.createSlug || createFormError.uniqueSlug ? 'input-error' : ''}`} />

          <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createFormError.uniqueSlug ? '' : createFormError.createSlug ? 'error' : ''}`}>
            * This field is required!
          </p>
          <p data-cy="modal-create-category-name-error" className={`error__onblur ${createFormError.uniqueSlug ? 'error' : ''}`}>
            * This category slug already exists!
          </p>
        </div>

        <DropdownSelect type="create-category" data={attributes} update={[]} toggle={toggleCreate} handleOnSelect={handleOnSelect} setSelected={setSelected} />
        <div className="featured-cat-wrap">
          <input
            type="checkbox"
            name="createFeatured"
            id="createFeatured"
            required={false}
            onChange={(event) => {
              setIsFeatured((prev) => !prev);
              // onChange(event);
            }}
            checked={isFeatured}
          />
          <label className="label mb-1 d-block" htmlFor="createFeatured">
            <b>Featured category</b>
          </label>
        </div>
        <div className="form-group form-group-sidebar">
          <button data-cy="modal_create_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleCreate(false)}>
            <span className="text">Cancel</span>
          </button>

          {!createBtnLoader ? (
            <button data-cy="modal_create_submit-btn" type="submit" className="btn btn-primary" disabled={disabledCreate}>
              <span className="text">Create Category</span>
            </button>
          ) : (
            <button type="button" className="btn  btn-primary-outline">
              <span className="text">Creating...</span>
            </button>
          )}
        </div>
      </form>
    </SidebarModal>
  );
};
