import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data } = props;
	// PAGINATION
	const { pagination } = props;

	const formatDate = (isoString) => {
		const date = new Date(isoString);
		return date.toLocaleString(); // You can customize this format
	};

	console.log(data);

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						<th>Order</th>
						<th>Item</th>
						<th>User</th>
						<th>Status</th>
						<th>Transaction error</th>
						<th>Paymeny method</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td>{item.orderNumber}</td>
								<td>{item.product}</td>

								<td>{item.buyer}</td>
								<td> {item.status}</td>
								<td>{item.transactionError}</td>
								<td>{item.paymentMethod}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
