import React, { useEffect } from 'react';
import { Section, Row, Col, Wrapper } from '../../../../components/content';
import { ProductProvider, useProducts } from '../../../../context/products';
import { ProductBreadcrumb } from './product-breadcrumb/ProductBreadcrumb';
import Loading from '../../../../components/loading/Loading';
import { ProductInfo } from '../create-product/product-info/ProductInfo';
import { ProductGallery } from '../create-product/product-gallery/ProductGallery';
import { ProductOptions } from '../create-product/product-options/ProductOptions';
import { ProductTags } from '../create-product/product-tags/ProductTags';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import { PERMISSIONS } from '../../../../utils/permissions';
import ProductUpcCode from '../create-product/product-upccode/ProductUpcCode';
import { ProductPrice } from '../create-product/product-price/ProductPrice';

const UpdateProductLoader = (props) => {
	const { children } = props;
	const { getProduct, productId } = useProducts();


	useEffect(() => {
		//setProductEdit(true);
		getProduct(productId);
	}, []);

	return <React.Fragment>{children}</React.Fragment>;
};

const UpdateProductSubmit = () => {
	const { form, productData, onCancel, onUpdate } = useProducts();
	return (
		<footer className="product-save">
			<div className="actions">
				<button onClick={() => onCancel(form)} className="btn btn-primary-outline" type="button">
					<span className="text">Cancel</span>
				</button>

				<button onClick={() => onUpdate(`${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`)} className="btn btn-primary" type="button">
					<span className="text">Update Product</span>
				</button>
			</div>
		</footer>
	);
};

const UpdateProduct = () => {
	return (
		<ProductProvider>
			<ProductBreadcrumb />
			<Section className="section__product section__product--wrapper section__update-product">
				<UpdateProductLoader>
					<Row>
						<Col>
							<Wrapper className="side-left">
								<ProductInfo className="product__card--info" />
								<ProductPrice className="product__card--info" />
							</Wrapper>
						</Col>
						<Col>
							<Wrapper className="side-right">
								<ProductGallery className="product__card--gallery" />

								<ProductOptions className="product__card--options" />
							</Wrapper>
						</Col>
					</Row>
				</UpdateProductLoader>
				<UpdateProductSubmit />
			</Section>
		</ProductProvider>
	);
};

export default UpdateProduct;
