import React from 'react';
import { Input, Button, Form, Row } from 'antd';
import Modal from '../../../../../components/modal/Modal';

export const UpdateEmployeeStatusSingleChanges = ({ statusSingleProps, statusModalSingleFormRef, handleOnFinish, statusSingleFormFields, setToggleStatusSingle, deactivateBtnLoader }) => {
	return (
		<Modal {...statusSingleProps} className="sidebar__modal--center" title="">
			<Form name="updateStatusSingle" ref={statusModalSingleFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'status', data: data })} fields={statusSingleFormFields}>
				<h2 className="text-center mb-4">
					<span className="d-block">Are you sure you want to change </span>
					<span className="d-block">status of selected employee?</span>
				</h2>

				<Form.Item name="firstName" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item name="lastName" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="email" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="state" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="status" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="address" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="city" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="zip_code" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="phone" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="newPassword" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="role" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>
				<Form.Item name="statusSingleUserId" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item name="status" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item>
					<Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
						{!deactivateBtnLoader ? (
							<Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
								<span>Yes</span>
							</Button>
						) : (
							<Button type="button" className="btn btn-primary-link btn-block">
								<span>Updating...</span>
							</Button>
						)}

						<Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleStatusSingle(false)}>
							<span>No</span>
						</Button>
					</Row>
				</Form.Item>
			</Form>
		</Modal>
	);
};
