import React from 'react';
import { Section, Main } from '../../components/content';

const Home = () => {
	return (
		<>
			<h2 className="page-title">Dashboard</h2>
			<Section className="section__wrapper section__home">
				<Main className="section__content">
					<h1 style={{ padding: '20px', fontSize: '36px', fontWeight: '100', color: '#666', textAlign: 'center' }}>Welcome to Home page!</h1>
				</Main>
			</Section>
		</>
	);
};

export default Home;
