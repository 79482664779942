import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import DropdownSelect from '../DropdownSelect';

export const CreateSubcategory = ({ createSubcategoryProps, createSubcategoryFormRef, handleOnSubmit, createSubcategoryForm, handleOnChange, handleOnBlur, createSubcategoryFormError, attributes, toggleSubcategoryCreate, handleOnSelect, setSelected, setToggleSubcategoryCreate, createSubBtnLoader, disabledCreateSub }) => {
  return (
    <SidebarModal {...createSubcategoryProps} className="sidebar__modal--right form-subcategory" title="Create Subcategory">
      <form
        name="createSubcategory"
        ref={createSubcategoryFormRef}
        className="ant-form ant-form-horizontal"
        onSubmit={(event) =>
          handleOnSubmit({
            event: event,
            formRef: createSubcategoryFormRef
          })
        }
      >
        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createSubcategoryName">
            <b className="required">Name:</b>
          </label>

          <input name="createSubcategoryName" id="createSubcategoryName" value={createSubcategoryForm.createSubcategoryName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy="name-create-subcategory-input" className={`input ${createSubcategoryFormError.createSubcategoryName || createSubcategoryFormError.uniqueSubcategoryName ? 'input-error' : ''}`} />
          <p data-cy="modal-name-create-subcategory-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategoryName ? '' : createSubcategoryFormError.createSubcategoryName ? 'error' : ''}`}>
            * This field is required!
          </p>
          <p data-cy="modal-name-create-subcategory-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategoryName ? 'error' : ''}`}>
            * This category name already exists!
          </p>
        </div>

        <div className="form-group relative">
          <label className="label mb-1 d-block" htmlFor="createSubcategorySlug">
            <b className="required">Slug:</b>
          </label>

          <input name="createSubcategorySlug" id="createSubcategorySlug" value={createSubcategoryForm.createSubcategorySlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-create-subcategory-input" className={`input ${createSubcategoryFormError.uniqueSubcategorySlug || createSubcategoryFormError.createSubcategorySlug ? 'input-error' : ''}`} />

          <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategorySlug ? '' : createSubcategoryFormError.createSubcategorySlug ? 'error' : ''}`}>
            * This field is required!
          </p>
          <p data-cy="modal-create-category-slug-error" className={`error__onblur ${createSubcategoryFormError.uniqueSubcategorySlug ? 'error' : ''}`}>
            * This category slug already exists!
          </p>
        </div>

        <DropdownSelect type="create-subcategory" data={attributes} update={[]} toggle={toggleSubcategoryCreate} handleOnSelect={handleOnSelect} setSelected={setSelected} />

        <div className="form-group form-group-sidebar">
          <button data-cy="modal_create_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleSubcategoryCreate(false)}>
            <span className="text">Cancel</span>
          </button>

          {!createSubBtnLoader ? (
            <button data-cy="modal_create_submit-btn" type="submit" className="btn btn-primary" disabled={disabledCreateSub}>
              <span className="text">Create Subcategory</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">Creating ...</span>
            </button>
          )}
        </div>
      </form>
    </SidebarModal>
  );
};
