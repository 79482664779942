import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import Modal from '../../../components/modal/Modal';
import { Col, Row, Wrapper } from '../../../components/content';
import { useAuth } from '../../../context/useAuth';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import Loading from '../../../components/loading/Loading';
import { SortableList } from './SortableList';

const extansionRemoved = (str) => {
	if (str) {
		return str.replace(/.png|.jpg|.jpeg|.gif|.svg/g, '');
	} else {
		console.warn('Problem with string of undefined');
		return;
	}
};

const SlideshowImages = (props) => {
	const { data, setRefetch } = props;
	const { user } = useAuth();

	const [toggleImage, setToggleImage] = useState(false);
	const [toggleImageLoader, setToggleImageLoader] = useState(false);
	const [imageId, setImageId] = useState({ id: '' });
	const [imageData, setImageData] = useState({});
	const [imageDataLoading, setImageDataLoading] = useState(false);
	const [featuredImageError, setFeaturedImageError] = useState(false);

	const handleOnToggle = (id) => {
		setImageId({ id: id });
		setToggleImage(true);
	};

	const getImageById = useCallback(
		async (image) => {
			const id = image.id;

			if (id && id.length > 0) {
				setImageDataLoading(true);
				await axios
					.get(`${SERVER_URL}/${AXIOS_API_CALL.slideshowgalleryImages}/${id}`)
					.then((res) => {
						if (res.status === 200) {
							setImageData(res?.data);
						}
					})
					.catch((err) => {
						console.error(err);
						setImageDataLoading(false);
					})
					.finally(() => {
						setTimeout(() => {
							setImageDataLoading(false);
						}, 700);
					});
			}
		},
		[user]
	);

	useEffect(() => {
		getImageById(imageId);
		console.log(imageData);
	}, [imageId]);

	const getDateFormatMDY = (date) => {
		const d = new Date(date);
		const monthAndDay = d.toLocaleString('default', { month: 'long', day: 'numeric' });
		const year = d.getFullYear();

		return `${monthAndDay}, ${year}`;
	};

	const [form, setForm] = useState({
		id: '',
		imageName: '',
		imageAlt: '',
		sliderUrlRedirect: '',
		googleDescription: '',
		description: '',
	});

	useEffect(() => {
		if (imageId.id !== '' && Object.keys(imageData).length > 0) {
			const form = {
				id: imageId.id,
				imageName: imageData.key,
				imageAlt: imageData.altTitle,
				description: imageData.description,
				sliderUrlRedirect: imageData.sliderUrlRedirect,
				googleDescription: imageData.googleDescription,
			};

			setForm(form);
			console.log(form);
		}
	}, [imageId, imageData, setForm]);

	const onChange = (event) => {
		const { name, value } = event.target;

		setForm((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		const id = form.id;
		const token = user.token;

		let payload = {
			title: form.imageName,
			altTitle: form.imageAlt,
			description: form.description,
			sliderUrlRedirect: form.sliderUrlRedirect,
			googleDescription: form.googleDescription,
		};

		if (typeof form.imageAlt !== 'undefined' && form.imageAlt !== null) {
			payload = {
				...payload,
				title: form.imageName,
				altTitle: form.imageAlt,
				description: form.description,
				sliderUrlRedirect: form.sliderUrlRedirect,
				googleDescription: form.googleDescription,
			};
		}

		try {
			setToggleImageLoader(true);
			await axios
				.put(`${SERVER_URL}/${AXIOS_API_CALL.slideshowgalleryUpdateImages}/${id}`, { ...payload }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					console.log(payload);
					if (res.status === 200) {
						setRefetch((p) => !p);
						setToggleImage(false);
						setImageId({ id: '' });
						setImageData({});
						setToggleImageLoader(false);
						console.log(payload);
						// notification
						notification.success({
							message: 'Data is succesfully changed.',
							placement: 'bottomLeft',
						});
					}
				})
				.catch((err) => {
					console.log(payload);
					setFeaturedImageError(true);
					setToggleImageLoader(false);
				})
				.finally(() => {
					setToggleImageLoader(false);
				});
		} catch (err) {
			console.log(payload);
			console.log('err', err.message);
		}
	};

	const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
	const onDelete = async () => {
		const id = form.id;
		const token = user.token;

		try {
			setDeleteBtnLoader(true);
			await axios
				.delete(`${SERVER_URL}/${AXIOS_API_CALL.slideshowgalleryDeleteImages}/${id}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
				.then((res) => {
					if (res.status === 200) {
						setImageId({ id: '' });
						setToggleImage(false);
						setImageData({});
						setRefetch((p) => !p);
						setDeleteBtnLoader(false);

						// notifications
						notification.success({
							message: 'Image is deleted.',
							placement: 'bottomLeft',
						});
					}
				})
				.catch((err) => {
					setDeleteBtnLoader(false);
					console.error(err);
				})
				.finally(() => {
					setDeleteBtnLoader(false);
				});
		} catch (err) {
			console.log('err', err.message);
		}
	};

	// order data by featured items first
	const sortedData = data.sort((a, b) => {
		return a.positionIndex - b.positionIndex;
	});

	return (
		<>
			<Wrapper className="gallery-images">
				<Row>
					<SortableList>
						{sortedData &&
							sortedData.length > 0 &&
							sortedData.map((item) => {
								return (
									<Col key={item._id}>
										<div className="card" style={{ cursor: 'pointer' }} onClick={() => handleOnToggle(item._id)}>
											{/* {item.featured && <div className="featured-flag">Prva slika</div>} */}
											<img className="img" src={item.localPath} alt={item.originalname} />
										</div>
									</Col>
								);
							})}
					</SortableList>
				</Row>
			</Wrapper>

			<Modal active={toggleImage} onToggle={setToggleImage} setFeaturedImageError={setFeaturedImageError} title="Data for slider" className="gallery-view modal-full">
				<div className="view-layout relative">
					{!imageDataLoading ? (
						<>
							<div className="content-image relative">{imageData ? <img src={imageData?.localPath} /> : null}</div>

							<div className="content-info relative">
								<div className="information">
									<p>
										<b>Uploaded:</b>
										<span> {imageData && imageData?.updatedAt ? getDateFormatMDY(imageData?.updatedAt) : null}</span>
									</p>
								</div>

								<form
									onSubmit={(event) => {
										onSubmit(event);
									}}
									name="update-image"
									className="form form-update-images"
								>
									<div className="form-group">
										<label className="label" htmlFor="imageAlt">
											<b>Image alt:</b>
										</label>

										<input value={form.imageAlt || ''} onChange={onChange} name="imageAlt" id="imageAlt" className="input input-full" type="text" placeholder="Image Alt" />
									</div>

									<div className="form-group">
										<label className="label" htmlFor="imageAlt">
											<b>Description:</b>
										</label>

										<input value={form.description || ''} onChange={onChange} name="description" id="sliderDesc" className="input input-full" type="text" placeholder="Description" />
									</div>

									<div className="form-group">
										<label className="label" htmlFor="imageAlt">
											<b>Google description:</b>
										</label>

										<input value={form.googleDescription || ''} onChange={onChange} name="googleDescription" id="sliderGoogle" className="input input-full" type="text" placeholder="Google description" />
									</div>

									<div className="form-group">
										<label className="label" htmlFor="imageAlt">
											<b>Url redirection:</b>
										</label>

										<input value={form.sliderUrlRedirect || ''} onChange={onChange} name="sliderUrlRedirect" id="sliderUrlRedirect" className="input input-full" type="text" placeholder="Url redirection" />
									</div>

									<div className="form-group">
										<div className="actions">
											{toggleImageLoader || deleteBtnLoader ? (
												<button className="btn btn-primary pl-2 pr-2" type="button">
													<span className="text">Saving...</span>
												</button>
											) : (
												<button className="btn btn-primary pl-2 pr-2" type="submit">
													<span className="text">Save changes</span>
												</button>
											)}
										</div>
									</div>
								</form>

								<div className="information">
									<div className="actions">
										{toggleImageLoader || deleteBtnLoader ? (
											<button type="button" className="btn btn-danger">
												<span className="text">Deleting...</span>
											</button>
										) : (
											<button onClick={() => onDelete()} type="button" className="btn btn-danger">
												<span className="text">Delete</span>
											</button>
										)}
									</div>
								</div>
							</div>
						</>
					) : (
						<Loading />
					)}
				</div>
			</Modal>
		</>
	);
};

export default SlideshowImages;
