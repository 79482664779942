const PERMISSIONS = {
	// STORE
	store: 'store',

	// PRODUCTS
	products: 'products',
	createProducts: 'create_products',
	readProducts: 'read_products',
	updateProducts: 'update_products',
	deleteProducts: 'delete_products',
	createProduct: 'create-product',
	updateProduct: 'update-product',
	productDetails: 'product-details',

	// PRODUCTS
	createShoes: 'create_shoes',
	readProducts: 'read_products',
	updateShoes: 'update_shoes',
	deleteShoes: 'delete_shoes',
	createProduct: 'create-product',
	updateProduct: 'update-product',
	productDetails: 'product-details',

	// BRANDS
	brands: 'brands',
	createBrands: 'create_brands',
	readBrands: 'read_brands',
	updateBrands: 'update_brands',
	deleteBrands: 'delete_brands',

	// ORDERS
	orders: 'orders',
	createOrders: 'create_orders',
	readOrders: 'read_orders',
	updateOrders: 'update_orders',
	deleteOrders: 'delete_orders',

	// GALLERY
	gallery: 'gallery',
	readGallery: 'read_images',
	createGallery: 'create_images',
	updateGallery: 'update_images',
	deleteGallery: 'delete_images',

	// ATTRIBUTES
	attributes: 'attributes',
	createAttributes: 'create_attributes',
	readAttributes: 'read_attributes',
	updateAttributes: 'update_attributes',
	deleteAttributes: 'delete_attributes',

	// ROLES
	roles: 'roles',
	createRoles: 'create_roles',
	readRoles: 'read_roles',
	updateRoles: 'update_roles',
	deleteRoles: 'delete_roles',

	// CUSTOMERS
	customers: 'customers',
	createCustomers: 'create_customers',
	readCustomers: 'read_customers',
	updateCustomers: 'update_customers',
	deleteCustomers: 'delete_customers',

	// EMPLOYEES
	employees: 'employees',
	createEmployees: 'create_employees',
	readEmployees: 'read_employees',
	updateEmployees: 'update_employees',
	deleteEmployees: 'delete_employees',

	// CATEGORIES
	categories: 'categories',
	createCategories: 'create_categories',
	readCategories: 'read_categories',
	updateCategories: 'update_categories',
	deleteCategories: 'delete_categories',

	// ACTIVITY
	activity: 'activity',
	readActivity: 'read_logs',

	// PROFILE
	profile: 'profile',
	readProfile: 'read_profile',
	updateProfile: 'update_profile',
	deleteProfile: 'delete_profile',

	// EMPLOYEE E-MAIL VERIFICATION
	employeeEmailVerification: 'employee-email-verification',

	// AUTH TYPE
	authType: 'basic',

	// SIGN IN PATH
	signInPath: 'dashboard',

	// LOGIN
	login: 'login',

	// FORGOT PASSWORD
	forgotPassword: 'forgot-password',

	// PASSWORD RESET
	passwordReset: 'password-reset',

	// DASHBOARD
	dashboard: 'admin',

	// GROCERY
	grocery: 'shoes',
	departmentGrocery: 'shoes',
};

const USER = 'user';

const SUPER_ADMIN = 'Super Admin';

export { PERMISSIONS, USER, SUPER_ADMIN };
