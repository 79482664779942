import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../config/index';
import { useAuth } from '../context/useAuth';
import ProtectedRoute from './ProtectedRouter';
import { jwtDecode } from 'jwt-decode';

// Auth
import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

// Catch page
import Home from '../pages/home-page/Home';
import Profile from '../pages/profile-page/Profile';
import NotFoundError from '../pages/error-page/NotFoundError';

// Component pages - GROCERY
import RolesGrocery from '../pages/grocery/roles-page/Roles';
import EmployeesGrocery from '../pages/grocery/employees-page/Employees';
import UsersGrocery from '../pages/grocery/users-page/Users';
import CustomersGrocery from '../pages/grocery/customers-page/Customers';
import ActivityGrocery from '../pages/grocery/activity-page/Activity';
import CategoriesGrocery from '../pages/grocery/categories-page/Categories';
import AttributesGrocery from '../pages/grocery/attributes-page/Attributes';
import OrdersGrocery from '../pages/grocery/orders-page/Orders';
import OrderGroceryDetails from '../pages/grocery/order-details-page/OrderDetails';
import GalleryGrocery from '../pages/grocery/gallery-page/Gallery';
import ProductsGrocery from '../pages/grocery/products-page/Products';
import AsksGrocery from '../pages/grocery/asks-page/Asks';
import CostsGrocery from '../pages/grocery/costs-page/Costs';
import AskLogsGrocery from '../pages/grocery/ask-logs-page/AskLogs';
import TarifsGrocery from '../pages/grocery/tarifs-page/Tarifs';
import BlockListGrocery from '../pages/grocery/block-list-page/BlockList';
import OrderAttemptsGrocery from '../pages/grocery/order-attempts-page/OrderAttempts';
import PromosGrocery from '../pages/grocery/promos-page/Promos';
import SettingsGrocery from '../pages/grocery/settings-page/Settings';
import PushNotificationsGrocery from '../pages/grocery/push-notifications-page/PushNotifications';
import SellerVerificationRequestsGrocery from '../pages/grocery/seller-verfication-requests-page/SellerVerificationRequests';
import DiscountsGrocery from '../pages/grocery/discounts-page/Discounts';
import BrandsGrocery from '../pages/grocery/brands-page/Brands';
import BannersGrocery from '../pages/grocery/slideshow-page/Slideshow';
import BrandsGroceryCreate from '../pages/grocery/brands-page/create-brands/CreateBrand';
import ProductGroceryCreate from '../pages/grocery/products-page/create-product/CreateProduct';
import ProductGroceryUpdate from '../pages/grocery/products-page/update-product/UpdateProduct';

// AUTH VERIFICATION APP
import { getGroceryPermissions } from '../middleware';
import { AXIOS_API_CALL } from '../utils/endpoint';
import { PERMISSIONS, USER } from '../utils/permissions';
import EmployeeEmailVerification from '../pages/auth/EmployeeEmailVerification';
import Documents from '../pages/grocery/documents-page/Documents';

const Router = (props) => {
	const { pathname } = props;
	const { user, refetchToken } = useAuth();
	const location = useLocation();

	const [userRoleName, setUserRoleName] = useState('');

	const getUserTokenStatus = async () => {
		const checkUser = window.sessionStorage.getItem(USER);

		if (!checkUser || checkUser.includes('null') || checkUser.includes('undefined')) {
			window.sessionStorage.setItem(USER, JSON.stringify(null));
			window.location.href = `/${PERMISSIONS.login}`;
			return false;
		}

		const jsonToken = JSON.parse(checkUser);

		if (!jsonToken) {
			window.sessionStorage.setItem(USER, JSON.stringify(null));
			window.location.href = `/${PERMISSIONS.login}`;

			return false;
		}

		try {
			const fetchToken = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.userCheckToken}`, null, { withCredentials: false, headers: { Authorization: `Bearer ${jsonToken.token}` } });

			if (fetchToken.status !== 200) {
				window.sessionStorage.setItem(USER, JSON.stringify(null));
				window.location.href = `/${PERMISSIONS.login}`;
			}
		} catch (err) {
			window.sessionStorage.setItem(USER, JSON.stringify(null));
			window.location.href = `/${PERMISSIONS.login}`;
		}
	};

	// TOKEN VALIDATION
	useEffect(() => {
		if (user && user.token) {
			const checkUser = window.sessionStorage.getItem(USER);

			if (!checkUser || checkUser.includes('null') || checkUser.includes('undefined')) {
				window.sessionStorage.setItem(USER, JSON.stringify(null));
				window.location.href = `/${PERMISSIONS.login}`;
				return;
			}

			const jsonToken = JSON.parse(checkUser);

			if (!jsonToken) {
				window.sessionStorage.setItem(USER, JSON.stringify(null));
				window.location.href = `/${PERMISSIONS.login}`;
				return;
			}
			getUserTokenStatus(jsonToken);
		}
	}, [user, location, refetchToken]);

	// CHECK USER SESSION STORAGE
	useEffect(() => {
		const checkUser = window.sessionStorage.getItem(USER);
		if (!checkUser) {
			window.sessionStorage.setItem(USER, JSON.stringify(null));
			window.location.href = `/${PERMISSIONS.login}`;
			window.location.reload();
		}
	}, [location]);

	const [groceryPermissions, setGroceryPermissions] = useState([]);
	// GET USER ROLE NAME FROM STORAGE - DECODE
	useEffect(() => {
		if (user && user.token) {
			const decodeToken = jwtDecode(user.token);

			if (decodeToken && decodeToken.roleData) {
				setUserRoleName(decodeToken.roleData.roleName);
				const permissions = decodeToken.roleData.permissions;
				setGroceryPermissions(permissions.grocery || []);
			}
		}
	}, [user, location]);

	const getLocationPathname = useCallback(
		(path) => {
			if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${path}`)) return true;
			return false;
		},
		[location]
	);

	return (
		<Routes>
			{/* public routes */}
			<Route index element={<Navigate to={`/${PERMISSIONS.login}`} replace />} />
			<Route path={`/${PERMISSIONS.login}`} element={<Login />} />
			<Route path={`/${PERMISSIONS.forgotPassword}`} element={<ForgotPassword />} />
			<Route path={`/${PERMISSIONS.passwordReset}/:id`} element={<ResetPassword />} />
			<Route path={`/${PERMISSIONS.employeeEmailVerification}/:token`} element={<EmployeeEmailVerification />} />

			{/* protected routes */}
			<Route element={<ProtectedRoute pathname={pathname} redirectPath={`/${PERMISSIONS.login}`} />}>
				<Route index path={`/${PERMISSIONS.dashboard}`} element={<Navigate to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`} replace />} />

				{/* GROCERY */}
				{getLocationPathname(PERMISSIONS.grocery) ? (
					<>
						<Route index path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`} element={<Home />} />
						<Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`} element={<Profile />} />
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readRoles) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`} element={<RolesGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readCustomers) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}`} element={<CustomersGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`} element={<EmployeesGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readEmployees) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/users`} element={<UsersGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readActivity) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.activity}`} element={<ActivityGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readCategories) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.categories}`} element={<CategoriesGrocery />} />}
						{<Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`} element={<AttributesGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`} element={<OrdersGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readOrders) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}/:id`} element={<OrderGroceryDetails />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readGallery) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`} element={<GalleryGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`} element={<ProductsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/discounts`} element={<DiscountsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/promos`} element={<PromosGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/settings`} element={<SettingsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/tarifs`} element={<TarifsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/push-notifications`} element={<PushNotificationsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/seller-verfication-requests`} element={<SellerVerificationRequestsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/block-list`} element={<BlockListGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/order-attempts`} element={<OrderAttemptsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/asks`} element={<AsksGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/banners`} element={<BannersGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/ask-logs`} element={<AskLogsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/costs`} element={<CostsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/legal-documents`} element={<Documents />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readBrands) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.brands}`} element={<BrandsGrocery />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.readBrands) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createBrands}`} element={<BrandsGroceryCreate />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.createProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createProduct}`} element={<ProductGroceryCreate />} />}
						{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && <Route path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateProduct}/:productId`} element={<ProductGroceryUpdate />} />}
					</>
				) : null}

				{/* catch all */}
				<Route path="*" element={<NotFoundError />} />
			</Route>
		</Routes>
	);
};

export default Router;
