import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../../config/index';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { useAuth } from '../../../context/useAuth';
import { Button, Upload, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Wrapper } from '../../../components/content';
import { useProducts } from '../../../context/products';
import { useNavigate } from 'react-router-dom';

const { Dragger } = Upload;

const GalleryUpload = ({ data, props, onImageUpload }) => {
	const { setSelectImage } = useProducts() || {};
	const { user } = useAuth();
	const [fileList, setFileList] = useState([]);
	const [fileImg, setFileImage] = useState('');
	const navigate = useNavigate();

	const uploadProps = {
		name: 'image',
		accept: '.jpg,.jpeg,.png',
		multiple: props.multiple,
		action: `${SERVER_URL}/${AXIOS_API_CALL.slideshowuploadLocalImage}/${user.id}`,
		headers: {
			uri: 'public/images/common/',
			Authorization: `Bearer ${user.token}`,
			slideshow: props.slideshow,
			positionindex: (data && Number(data?.length)) || 0,
		},
		data: {
			type: props.type, // This is added here
		},
		onChange(info) {
			const { status, response } = info.file;

			if (status === 'done') {
				if (props.type === 'product') {
					setSelectImage([response.data._id]);
				}
				console.log('Server Response:', response);
				const imageUrl = response?.data.url; // Ensure the response has the `url` field
				console.log(imageUrl, fileList);
				if (imageUrl) {
					onImageUpload(imageUrl);
					setFileList([]);
				} else {
					notification.error({
						message: 'Failed to get image URL from server response.',
					});
				}
				console.log(fileList);
			}
			if (status === 200) {
				notification.success({
					message: 'Image added successfully.',
					placement: 'bottomLeft',
				});
			}
			if (status === 'error') {
				notification.error({
					message: response?.message || 'Upload failed.',
				});
			}
		},
		onDrop(e) {
			console.warn('Dropped files', e.dataTransfer.files);
		},
		onRemove(file) {
			// Handle file removal if needed
			setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
		},
	};

	return (
		<Dragger className="upload-area" {...uploadProps}>
			<Wrapper className="relative">
				<div className="block relative">
					<span className="image">
						<img alt="Upload" src="/assets/icons/upload_image.svg" />
					</span>
					<h3>Drag file for upload</h3>
					<p>or</p>

					<Button className="btn btn-primary" icon={<UploadOutlined />}>
						Upload image
					</Button>

					<p>Max file size: 2 MB.</p>
				</div>
			</Wrapper>
		</Dragger>
	);
};

export default GalleryUpload;
