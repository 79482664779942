import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import Loading from '../../../../components/loading/Loading';

export const ViewUserData = ({ viewProps, viewLoading, viewData, handleClose }) => {
	
	const renderAddress = () => {
		if (viewData.address && viewData.address.length > 0) {
			return viewData.address.map((addr, idx) => (
				<p key={`address_${idx}`} className="value viewValue">
					{addr.street ? `${addr.street}, ` : ''}
					{addr.zip_code ? `${addr.zip_code}, ` : ''}
					{addr.city ? `${addr.city}, ` : ''}
					{addr.state ? `${addr.state}` : ''}
				</p>
			));
		}
		return <p className="value viewValue">No address available</p>;
	};
	return (
		<SidebarModal {...viewProps} className="sidebar__modal--right" title="View Details">
			{!viewLoading ? (
				<div className="view-details">
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewFirstName">
							<b>First Name:</b>
						</label>
						<p id="viewFirstName" className="value viewValue">
							{viewData.firstName || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewLastName">
							<b>Last Name:</b>
						</label>
						<p id="viewLastName" className="value viewValue">
							{viewData.lastName || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewEmail">
							<b>Email:</b>
						</label>
						<p id="viewEmail" className="value viewValue">
							{viewData.email || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewPhone">
							<b>Phone:</b>
						</label>
						<p id="viewPhone" className="value viewValue">
							{viewData.phone || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewAddress">
							<b>Address:</b>
						</label>
						
							{renderAddress()}
						
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStatus">
							<b>Status:</b>
						</label>
						<p id="viewStatus" className="value viewValue">
							{viewData.status || ''}
						</p>
					</div>

					<div className="form-group form-group-sidebar">
						<button data-cy="modal-close-btn" type="button" className="btn btn-primary-link" onClick={handleClose}>
							<span className="text">Close</span>
						</button>
					</div>
				</div>
			) : (
				<Loading />
			)}
		</SidebarModal>
	);
};
