import React, { useState, useEffect } from 'react';

import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';
// import GalleryUpload, { BrandLogoUpload } from '../BrandLogoUpload';
import GalleryUpload from '../../slideshow-page/GalleryUpload';
import Loading from '../../../../components/loading/Loading';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';

export const UpdateBrand = ({ updateProps, uploadLogoProps, loadingBrand, updateModalFormRef, updateFormFields, editBtnLoader, onSubmit, setToggleUpdate }) => {
	const [imageData, setImageData] = useState(uploadLogoProps.imageData?.blob || '');

	// Update imageData when uploadLogoProps.imageData changes
	useEffect(() => {
		setImageData(uploadLogoProps.imageData?.blob || '');
	}, [uploadLogoProps.imageData]);

	const handleImageUpload = (image) => {
		console.log('Image uploaded:', image); // Log the image received
		setImageData(image); // Update state
	};

	const imageProps = {
		type: 'brand',
		slideshow: false,
		navigate: '/admin/shoes/brands',
		multiple: false,
	};

	return (
		<SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit User">
			{!loadingBrand ? (
				<Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => onSubmit({ event, name: 'update', data: { ...data, image: imageData } })} fields={updateFormFields}>
					<Form.Item name="updateBrandId" style={{ display: 'none' }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item
						name="name"
						label="Brand Name:"
						rules={[
							{
								required: true,
								message: 'Please input brand name!',
							},
						]}
					>
						<Input placeholder="Brand name:" />
					</Form.Item>
					<div className="">
						{imageData && <img src={imageData} alt="Uploaded brand logo" width={100} height={120} style={{ border: 'dashed 1px gray', objectFit: 'contain' }} />}

						<GalleryUpload props={imageProps} onImageUpload={handleImageUpload} />
					</div>

					<Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
						<Row gutter={16}>
							<Col span={12}>
								<Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
									<span>Cancel</span>
								</Button>
							</Col>
							<Col span={12}>
								{!editBtnLoader ? (
									<Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
										<span>Save Changes</span>
									</Button>
								) : (
									<Button type="button" className="btn btn-primary-outline btn-block">
										<span>Editing...</span>
									</Button>
								)}
							</Col>
						</Row>
					</Form.Item>
				</Form>
			) : (
				<Loading />
			)}
		</SidebarModal>
	);
};
