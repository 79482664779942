import React from 'react';

export const BrandLogoUpload = ({ props, onImageUpload  }) => {
  const {handleOnRemoveUpload, handleOnUpload, createFormError, imageData} = props;
  
  console.log(imageData)
					return (
						<div className={`form-group upload-image upload-image-blog-wrapper`}>
							{imageData.blob ? (
								<div className="avatar">
									<div className="image-wrapper">
										<img src={imageData.blob} alt="Parent category blob" data-cy="actual-image" className="img" />
									</div>

									<button type="button" data-cy="remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'create' })}>
										<span className="icon" data-cy="image-placeholder"></span>
										<span className="text">Delete Image</span>
									</button>
								</div>
							) : (
								<div className="upload">
									<div className="upload-wrapper d-flex gap-x-2">
										<div>
											<span className="text" style={{ zIndex: '0' }}>
												<div className="text-center">
													<p className="mb-0">Image is not</p>
													<p className="mb-0">selected</p>
												</div>
											</span>
											<input
												type="file"
												name="createImage"
												id="uploadFile"
												className="input-upload"
												onChange={(event) =>
													handleOnUpload({
														name: event.target.name,
														img: event.target.files[0],
													})
												}
											/>
										</div>

										<div className="validation-image">
											<p data-cy="modal-create-category-slug-error" className={`${createFormError.imageType ? 'error' : ''}`}>
												* Only PG/PNG file are allowed!
											</p>

											<p data-cy="modal-create-category-slug-error" className={`${createFormError.imageSize ? 'error' : ''}`}>
												* Image can be max 2MB!
											</p>
										</div>
									</div>
									<label htmlFor="uploadFile" data-cy="upload-image-btn" className="label btn btn-primary-outline btn-no-hover">
										<span className="icon">
											<img src="/assets/icons/upload.svg" alt="Upload parent category" className="img" />
										</span>
										<span className="text">Add image</span>
									</label>
								</div>
							)}
						</div>
					);
				};
