import React, { useState, useEffect, useCallback } from 'react';
import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../../context/useAuth';
import { AXIOS_API_CALL } from '../../../../../utils/endpoint';
import { PERMISSIONS } from '../../../../../utils/permissions';

export const ProductPrice = (props) => {
	const { form, onChange, formError } = useProducts();
	const { className } = props;
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [activeSizeTab, setActiveSizeTab] = useState('');
	const { productAvailableSizes, productGoatPrices, productStockxPrices } = form;



	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(token);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	useEffect(() => {
		if (productAvailableSizes.length > 0) {
			setActiveSizeTab(productAvailableSizes[0]);
		}
	}, [productAvailableSizes]);

	// Handle tab switching
	const handleTabSwitch = (size) => {
		setActiveSizeTab(size);
	};

	return (
		<Wrapper className={`product__card ${className}`}>
			<div className="wrapper-element p-0">
				<header className="card-header">
					<h4>Product Prices by Size</h4>
				</header>

				<main className="card-content relative">
					{/* <form name="stockxfee" className="form">
						<div className="form-group">
							<label className="label" htmlFor="productStockxFee">
								<b>StockX Fee:</b>
							</label>
							<input value={form.productStockxFee || ''} onChange={onChange} name="productStockxFee" placeholder="StockX Fee" type="number" id="productStockxFee" className={`input ${formError.productStockxFee ? 'error' : ''}`} /> <span className="inputHelper">%</span>
							<p data-cy="" className={`error__onblur ${formError.productStockxFee ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
					</form> */}
					{productAvailableSizes.length > 0 ? (
						<>
							{/* Tab Navigation - Sizes */}
							<div className="tabs tabs-price">
								{productAvailableSizes.map((size, index) => (
									<button key={index} className={`tab ${activeSizeTab === size ? 'active' : ''}`} onClick={() => handleTabSwitch(size)}>
										{size}
									</button>
								))}
							</div>

							{/* Tab Content for the active size */}
							<div className="tab-content prices">
								{productAvailableSizes.map((size) => {
									if (size === activeSizeTab) {
										const goatPrice = productGoatPrices.find((price) => price.size === size)?.price || 0;
										const stockxPrice = productStockxPrices.find((price) => price.size === size)?.price || 0;
										return (
											<div key={size}>
												<form name={`prices-${size}`} className="form">
													{/* GOAT Price */}
													<div className="form-group">
														<label className="label" htmlFor={`goatPrice-${size}`}>
															<b>GOAT Price:</b>
														</label>
														<input value={goatPrice} onChange={onChange} name="productGoatPrices" placeholder="GOAT Price" type="number" id={`goatPrice-${size}`} className={`input ${formError[`goatPrice-${size}`] ? 'error' : ''}`} />
														<span className="inputHelper">$</span>
													</div>

													{/* Stockx Price */}
													<div className="form-group">
														<label className="label" htmlFor={`stockxPrice-${size}`}>
															<b>Stockx Price:</b>
														</label>
														<input value={stockxPrice} onChange={onChange} name="productStockxPrices" placeholder="Stockx Price" type="number" id={`stockxPrice-${size}`} className={`input ${formError[`stockxPrice-${size}`] ? 'error' : ''}`} />
														<span className="inputHelper">$</span>
													</div>
												</form>
											</div>
										);
									}
									return null;
								})}
							</div>
						</>
					) : (
						<p>No sizes available, please select a size in 'Available sizes'</p>
					)}
				</main>
			</div>
		</Wrapper>
	);
};
