import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Loading from '../../../components/loading/Loading';
import Table from './Table';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import { getGroceryPermissions } from '../../../middleware';
import { notification } from 'antd';
import UnderConstruction from '../block-list-page/UnderConstruction';

const options = [5, 10, 20, 50, 100];

const AskLogs = () => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	// READ
	const [readData, setReadData] = useState([]);
	const [readDataRefetch, setReadDataRefetch] = useState(false);
	const [readDataLoading, setReadDataLoading] = useState(true);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });
	const [filterStatus, setFilterStatus] = useState(null);

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setReadDataRefetch((prevState) => !prevState);
		console.log(search);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setReadDataRefetch((prevState) => !prevState);
	};

	// PAGE
	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);

	// FILTER STATUS
	const handleStatusFilter = (value) => {
		setFilterStatus(value);
		setReadDataRefetch((prevState) => !prevState);
	};

	// FILTERS
	const [disabledButton, setDisabledButton] = useState(false);
	const handleDisabledButton = (status) => {
		setDisabledButton(status);
	};

	// Get selected rows id one or more
	const [checkedRow, setCheckedRow] = useState([]);
	const handleCheckedRows = (data) => {
		setCheckedRow(data);
	};

	const getReadData = useCallback(async () => {
		const { token } = user;

		const payload = {
			department: PERMISSIONS.grocery,
			searchTerm: search,
		};

		try {
			const response = await axios
				.get(`${SERVER_URL}/${AXIOS_API_CALL.discountsProducts}`, { ...payload }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					console.log('Response data:', res.data);
					return res;
				})
				.catch((err) => console.error(err))
				.finally(
					setTimeout(() => {
						setReadDataLoading(false);
					}, 200)
				);
			return response;
		} catch (err) {
			console.error('Error fetching data:', err);
		}
	}, [user, limitPage, search, currentPage, filterStatus]);

	useEffect(() => {
		let isMounted = true;

		new Promise((resolve, reject) => {
			setReadDataLoading(true);
			setTimeout(() => {
				resolve(getReadData());
			}, 700);
		}).then((res) => {
			if (isMounted) {
				setReadData(res?.data);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [getReadData, readDataRefetch]);

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search, setCurrentPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [limitPage, setCurrentPage]);

	// FUNCTION
	function handleToggle(props) {
		const { name, state, data } = props;

		switch (name) {
			case 'create':
				state.onToggle(true);
				break;
			case 'update':
				state.onToggle(true);
				break;

			default:
				console.warn('Default of: handleToggle function');
		}
	}

	async function onSubmit(props) {
		const { event, name, data } = props;
		event.preventDefault();
		const { token } = user;
	}

	// CREATE
	const createModalFormRef = useRef(null);
	const [toggleCreate, setToggleCreate] = useState(false);
	const [createForm, setCreateForm] = useState({}); // data

	// UPDATE
	const updateModalFormRef = useRef(null);
	const [toggleUpdate, setToggleUpdate] = useState(false);
	const [updateFormId, setUpdateFormId] = useState({ id: '' });
	const [updateForm, setUpdateForm] = useState({}); // data

	// create
	const createProps = {
		active: toggleCreate,
		onToggle: setToggleCreate,
		formRef: createModalFormRef,
		data: createForm,
		onData: setCreateForm,
	};

	// update
	const updateProps = {
		active: toggleUpdate,
		onToggle: setToggleUpdate,
		formRef: updateModalFormRef,
		data: updateForm,
		onData: setUpdateForm,
	};

	// PROPS
	const tableProps = {
		handleDisabledButton: handleDisabledButton,
		handleCheckedRows: handleCheckedRows,
	};

	const tableActionsProps = {
		handleToggle: handleToggle,

		updateActions: updateProps,

		location: location,
		groceryPermissions: groceryPermissions,
		getGroceryPermissions: getGroceryPermissions,
	};

	const tableFiltersProps = {
		handleStatusFilter: handleStatusFilter,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: 1,
		totalCount: 1,
	};

	console.log(readData);

	return (
		<>
			<h2 className="page-title">Ask Logs</h2>
			<Section className="section__wrapper section__products">
				<header className="section__header">
					{!readDataLoading ? (
						<div className="filter">
							<span style={{ color: '#333', fontWeight: '500' }}>Show:</span>
							<SelectCustom options={options} label={'Items'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
					)}
					{!readDataLoading ? (
						<div className="search">
							<form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
								<div className="form-group m-0">
									<span className="icon icon-search">
										<img src="/assets/icons/search.svg" alt="Search" title="Search" />
									</span>
									<input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
									<span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
										<img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
									</span>
								</div>
								<button className="form-submit" data-cy="search-submit-btn">
									Search
								</button>
							</form>
						</div>
					) : (
						<LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
					)}
					<div className="actions">
						{/* {getGroceryPermissions(groceryPermissions, PERMISSIONS.createProducts) && ( */}
						<>
							{!readDataLoading ? (
								<Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createProduct}`} className="btn btn-primary pl-4 pr-4 d-flex items-center">
									<span className="text d-flex items-center align-center">New Ask Log</span>
								</Link>
							) : (
								<LoadingPlaceholder style={{ width: '162.5px', height: '50px' }} />
							)}
						</>
						{/* )} */}
					</div>
				</header>
				{/* Main content */}
				<Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData} {...tableProps} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>
			</Section>
		</>
	);
};

export default AskLogs;
