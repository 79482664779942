import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../useAuth';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

// Create the context
const BrandContext = createContext();

// Define the provider component
export const BrandProvider = ({ children }) => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [brandEdit, setBrandEdit] = useState(false);
	const [brandCategoriesLoading, setBrandCategoriesLoading] = useState(false);
	const [imageDataUrl, setImageDataUrl] = useState('');

	const token = user.token;

	console.log(imageDataUrl);

	const [form, setForm] = useState({
		brandName: '',
		brandLogo: '',
	});

	const [formError, setFormError] = useState({ brandName: null, brandLogo: null });
	// Define your functions
	const onSubmit = useCallback(async () => {
		// Perform validation if needed
		console.log(imageDataUrl);
		if (!form.brandName) {
			setFormError({ ...formError, brandName: 'Brand Name is required!' });
			return;
		}
		if (!imageDataUrl) {
			notification.error({
				message: 'Please upload brand image.',
				placement: 'bottomLeft',
			});
			return;
		}
		try {
			const brandPayload = {
				name: form.brandName,
				selectedIcon: imageDataUrl,
				unselectedIcon: imageDataUrl,
			};

			const response = await axios.post(
				`${SERVER_URL}/${AXIOS_API_CALL.createBrands}`, // Adjust endpoint as needed
				brandPayload,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			);

			if (response.status === 201) {
				// Redirect or perform actions on success

				// Show success notification
				notification.success({
					message: 'Successfully created the brand.',
					placement: 'bottomLeft',
				});
				setTimeout(() => {
					navigate('/admin/shoes/brands');
				}, 1000);
			}
		} catch (error) {
			console.error('Error submitting brand data:', error);

			// Show error notification
			notification.error({
				message: 'Failed to create the brand, missing name or image.',
				description: error.message,
				placement: 'bottomLeft',
			});
		}
	}, [form, formError, navigate, token, imageDataUrl]);

	const onCancel = () => {
		navigate('/admin/shoes/brands');
	};

	const handleBrandNameChange = (e) => {
		setBrandName(e.target.value);
	};

	const onChange = (event) => {
		const { name, value } = event.target;
		setForm((prevForm) => ({
			...prevForm,
			[name]: value,
		}));
	};

	const value = {
		brandEdit,
		setBrandEdit,
		brandCategoriesLoading,
		setBrandCategoriesLoading,
		handleBrandNameChange,
		onSubmit,
		onCancel,
		onChange,
		form,
		formError,
		setImageDataUrl,
	};

	return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>;
};

// Custom hook to use the context
export const useBrands = () => {
	const context = useContext(BrandContext);
	if (!context) {
		throw new Error('useBrands must be used within a BrandProvider');
	}
	return context;
};
