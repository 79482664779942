import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';

export const VerifyForm = ({ verifyProps, verifyModalFormRef, handleOnFinish, verifyFormFields, verifyBtnLoader, setToggleVerify }) => {
	return (
		<Modal {...verifyProps} className="sidebar__modal--center" title="">
			<Form name="verify" ref={verifyModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'verify', data: data })} fields={verifyFormFields}>
				<h2 className="text-center mb-4">Are you sure you want to verify the selected item ?</h2>

				<Form.Item name="verifyUserId" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item>
					<Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
						{!verifyBtnLoader ? (
							<Button type="submit" htmlType="submit" className="btn btn-primary-link btn-verify">
								<span>Yes</span>
							</Button>
						) : (
							<Button type="button" className="btn btn-primary-link btn-verify">
								<span>Deleting...</span>
							</Button>
						)}

						<Button type="primary" htmlType="button" className="btn btn-primary btn-verify" onClick={() => setToggleVerify(false)}>
							<span>No</span>
						</Button>
					</Row>
				</Form.Item>
			</Form>
		</Modal>
	);
};
