import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';
import Loading from '../../../../components/loading/Loading';

export const ViewDetails = ({ viewProps, viewLoading, viewData, handleClose }) => {
	return (
		<SidebarModal {...viewProps} className="sidebar__modal--right" title="View Details">
			{!viewLoading ? (
				<div className="view-details">
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewFirstName">
							<b>First Name:</b>
						</label>
						<p id="viewFirstName" className="value">
							{viewData.firstName || 'N/A'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewLastName">
							<b>Last Name:</b>
						</label>
						<p id="viewLastName" className="value">
							{viewData.lastName || 'N/A'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewEmail">
							<b>Email:</b>
						</label>
						<p id="viewEmail" className="value">
							{viewData.email || 'N/A'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewPhone">
							<b>Phone:</b>
						</label>
						<p id="viewPhone" className="value">
							{viewData.phone || 'N/A'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewAddress">
							<b>Address:</b>
						</label>
						<p id="viewAddress" className="value">
							{viewData.address ? `${viewData.address}, ${viewData.zipCode}, ${viewData.city}, ${viewData.state}` : 'N/A'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStatus">
							<b>Status:</b>
						</label>
						<p id="viewStatus" className="value">
							{viewData.status || 'N/A'}
						</p>
					</div>

					<div className="form-group form-group-sidebar">
						<button data-cy="modal-close-btn" type="button" className="btn btn-primary-link" onClick={handleClose}>
							<span className="text">Close</span>
						</button>
					</div>
				</div>
			) : (
				<Loading />
			)}
		</SidebarModal>
	);
};
