import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Input, Button, Form, Row } from 'antd';

export const BlockForm = ({ blockProps, blockModalFormRef, handleOnFinish, blockFormFields, blockBtnLoader, setToggleBlock }) => {
	console.log(blockProps);
	return (
		<Modal {...blockProps} className="sidebar__modal--center" title="">
			<Form name="block" ref={blockModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'block', data: data })} fields={blockFormFields}>
				<h2 className="text-center mb-4">Are you sure you want to block the selected item ?</h2>

				<Form.Item name="blockUserId" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>

				<Form.Item>
					<Row gutter={16} style={{ justifyContent: 'center', columnGap: '20px' }}>
						{!blockBtnLoader ? (
							<Button type="submit" htmlType="submit" className="btn btn-primary-link btn-block">
								<span>Yes</span>
							</Button>
						) : (
							<Button type="button" className="btn btn-primary-link btn-block">
								<span>Deleting...</span>
							</Button>
						)}

						<Button type="primary" htmlType="button" className="btn btn-primary btn-block" onClick={() => setToggleBlock(false)}>
							<span>No</span>
						</Button>
					</Row>
				</Form.Item>
			</Form>
		</Modal>
	);
};
