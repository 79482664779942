import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data, handleToggle } = props;
	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS
	const { deleteActions, groceryPermissions, getGroceryPermissions } = props;

	console.log(data);

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						{/*
						 */}
						<th>Name</th>
						<th>ColorWay</th>
						<th>Product</th>
						{/* <th>Condition</th> */}
						<th>Price</th>
						<th>Size</th>
						<th>Product Image</th>
						<th>Status</th>
						<th>Status Reason</th>
						<th>Seller</th>
						{/* <th>Active Kinds Human</th> */}
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((shoe) =>
							shoe.sizes.map((size) =>
								size.asks.map((ask, idx) => (
									<tr key={ask._id}>
										{/* <td>{ask._id}</td> */}
										<td>{shoe.name}</td>
										<td>{shoe.colorway}</td>
										<td>{ask.sku}</td>
										{/* <td> </td> */}
										<td>{ask.regularPrice}</td>
										<td>{size.size}</td>
										<td>{shoe.images && shoe.images.length > 0 ? <img className="img" src={shoe.images[0].url} alt={shoe.name} width={120} style={{ borderRadius: '0.25rem' }} /> : <img className="img" src="/assets/images/product-placeholder.svg" alt={shoe.name} width={60} style={{ borderRadius: '0.25rem' }} />}</td>

										<td> {/* Add status if available */}</td>
										<td> {/* Add status reason if available */}</td>
										<td>{ask.seller}</td>
										{/* <td> </td> */}
									</tr>
								))
							)
						)
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
