import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import DropdownSelect from '../DropdownSelect';
import Loading from '../../../../components/loading/Loading';

export const UpdateCategory = ({ updateProps, loadingUpdate, updateFormRef, handleOnSubmit, createFormParent, updateForm, handleOnRemoveUpload, imageUpdateData, handleOnUpload, updateFormError, updateParentFormId, updateFormDropdown, attributes, updateDataAttribute, toggleUpdate, handleOnSelect, setSelected, setToggleUpdate, editBtnLoader, disabledUpdate, handleOnChange, handleOnBlur, isFeatured, setIsFeatured }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Category">
      {!loadingUpdate ? (
        <form name="update" ref={updateFormRef} className="ant-form ant-form-horizontal" onSubmit={(event) => handleOnSubmit({ event: event, formRef: updateFormRef })}>
          {createFormParent.parent && (
            <div className="form-group upload-image">
              {!!updateForm.image && updateForm.image.length !== 0 ? (
                <>
                  <div className="avatar">
                    <div className="image-wrapper">
                      <img src={getImageUrl(updateForm.image.url)} alt="Update category" data-cy="parent-update-category-image" className="img" />
                    </div>

                    <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'updateRemove' })}>
                      <span className="icon"></span>
                      <span className="text">Remove Image</span>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {imageUpdateData.blob ? (
                    <div className="avatar">
                      <div className="image-wrapper">
                        <img src={imageUpdateData.blob} alt="Update category blob" data-cy="update-parent-new-image" className="img" />
                      </div>

                      <button type="button" data-cy="update-parent-remove-image-btn" className="btn btn-danger-outline btn-block" onClick={() => handleOnRemoveUpload({ name: 'update' })}>
                        <span className="icon"></span>
                        <span className="text">Remove Image</span>
                      </button>
                    </div>
                  ) : (
                    <div className="upload">
                      <div className="upload-wrapper d-flex gap-x-2">
                        <div>
                          <span className="text" data-cy="update-parent-image-placeholder">
                            <div className="text-center">
                              <p className="mb-0">No Image</p>
                              <p className="mb-0">selected</p>
                            </div>
                          </span>
                          <input
                            type="file"
                            name="updateImage"
                            id="updateUploadFile"
                            className="input-upload"
                            onChange={(event) =>
                              handleOnUpload({
                                name: event.target.name,
                                img: event.target.files[0]
                              })
                            }
                          />
                        </div>

                        <div className="validation-image">
                          <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageType ? 'error' : ''}`}>
                            * You can only upload JPG/PNG file!
                          </p>

                          <p data-cy="modal-create-category-slug-error" className={`${updateFormError.imageSize ? 'error' : ''}`}>
                            * Image must smaller than 2MB!
                          </p>
                        </div>
                      </div>
                      <label htmlFor="updateUploadFile" data-cy="update-parent-upload-btn" className="label btn btn-primary-outline btn-no-hover">
                        <span className="icon">
                          <img src="/assets/icons/upload.svg" alt="Update category" className="img" />
                        </span>
                        <span className="text">Upload Image</span>
                      </label>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateName">
              <b className="required">Name:</b>
            </label>

            <input name="updateName" id="updateName" defaultValue={updateForm.updateName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Name" data-cy={createFormParent.parent ? 'update-name-category' : 'update-name-subcategory'} className={`input ${updateFormError.updateName || updateFormError.uniqueName ? 'input-error' : ''}`} />

            <p data-cy="modal-update-category-name-error" className={`error__onblur ${updateFormError.uniqueName ? '' : updateFormError.updateName ? 'error' : ''}`}>
              * This field is required!
            </p>
            <p data-cy="modal-update-category-name-error" className={`error__onblur ${updateFormError.uniqueName ? 'error' : ''}`}>
              * This category name already exists!
            </p>
          </div>
          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateSlug">
              <b className="required">Slug:</b>
            </label>

            <input name="updateSlug" id="updateSlug" value={updateForm.updateSlug || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Slug" data-cy="slug-update-input" className={`input ${updateFormError.updateSlug || updateFormError.uniqueSlug ? 'input-error' : ''}`} />

            <p data-cy="modal-update-category-slug-error" className={`error__onblur ${updateFormError.uniqueSlug ? '' : updateFormError.updateSlug ? 'error' : ''}`}>
              * This field is required!
            </p>
            <p data-cy="modal-update-category-slug-error" className={`error__onblur ${updateFormError.uniqueSlug ? 'error' : ''}`}>
              * This category slug already exists!
            </p>
          </div>

          {/* createFormParent.parent */}

          <div className="form-group">
            <label className="label mb-1 d-block" htmlFor="parentCategory">
              <b>Parent Category:</b>
            </label>

            <select className="select select-full" id="parentCategory" name="parentCategory" defaultValue={updateParentFormId && !!updateParentFormId.id ? updateParentFormId.id : 'none'} onChange={handleOnChange}>
              <option value="none">None</option>
              {updateFormDropdown &&
                updateFormDropdown.length > 0 &&
                updateFormDropdown.map((item, idx) => {
                  return (
                    <option key={idx} value={item._id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <DropdownSelect type="update-category" data={attributes} update={updateDataAttribute} toggle={toggleUpdate} handleOnSelect={handleOnSelect} setSelected={setSelected} />
          {createFormParent.parent && (
            <div className="featured-cat-wrap">
              <input
                type="checkbox"
                name="createFeatured"
                id="createFeatured"
                required={false}
                onChange={(event) => {
                  setIsFeatured((prev) => !prev);
                }}
                checked={isFeatured}
              />
              <label className="label mb-1 d-block" htmlFor="createFeatured">
                <b>Featured category</b>
              </label>
            </div>
          )}
          <div className="form-group form-group-sidebar">
            <button data-cy="modal_update_cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdate(false)}>
              <span className="text">Cancel</span>
            </button>

            {!editBtnLoader ? (
              <button data-cy="modal_update_submit-btn" type="submit" className="btn btn-primary" disabled={disabledUpdate}>
                <span className="text">Save Changes</span>
              </button>
            ) : (
              <button type="button" className="btn btn-primary-outline">
                <span className="text">Saving...</span>
              </button>
            )}
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
