import React, { useEffect, useRef, useState } from 'react';
import { useProducts } from '../../../../../../context/products';

// GENERATED OTP
const generateOtp = () => {
	return Math.floor(1000 + Math.random() * 9000);
};

const loadingContainer = {
	width: '100%',
	textAlign: 'center',
	display: 'grid',
	alignItems: 'center',
	columnGap: '15px',
	gridTemplateColumns: '24px',
};

const loadingAnimation = {
	width: '20px',
	height: '20px',
	border: '2px solid #e7e7e7',
	borderTop: '2px solid #4761e6',
	borderRadius: '50%',
	animation: 'spin 1s linear infinite',
	margin: 'auto',
	display: 'block',
};

function LoadingSpinner() {
	return (
		<div style={loadingContainer}>
			<span style={loadingAnimation} />
		</div>
	);
}

// CREATE ATTRIBUTE
export const Attributes = (props) => {
	const { form, formError, onChange, setSelectedSizes, selectedSizes } = useProducts();
	const [activeTab, setActiveTab] = useState('male');
	const [selectSizes, setSelectSizes] = useState([]);
	const [genders, setGenders] = useState([
		{ _id: 'male', name: 'Male' },
		{ _id: 'female', name: 'Female' },
		{ _id: 'unisex', name: 'Unisex' },
	]);

	const maleShoeSizes = ['US M 3.5', 'US M 4', 'US M 4.5', 'US M 5', 'US M 5.5', 'US M 6', 'US M 6.5', 'US M 7', 'US M 7.5', 'US M 8', 'US M 8.5', 'US M 9', 'US M 9.5', 'US M 10', 'US M 10.5', 'US M 11', 'US M 11.5', 'US M 12', 'US M 12.5', 'US M 13', 'US M 13.5', 'US M 14', 'US M 14.5', 'US M 15', 'US M 15.5'];
	const femaleShoeSizes = ['US W 5', 'US W 5.5', 'US W 6', 'US W 6.5', 'US W 7', 'US W 7.5', 'US W 8', 'US W 8.5', 'US W 9', 'US W 9.5', 'US W 10', 'US W 10.5', 'US W 11', 'US W 11.5', 'US W 12', 'US W 12.5', 'US W 13', 'US W 13.5', 'US W 14', 'US W 14.5', 'US W 15', 'US W 15.5', 'US W 16', 'US W 16.5', 'US W 17'];

	// Define equivalent size mappings
	const sizeEquivalents = {
		'US M 3.5': 'US W 5',
		'US M 4': 'US W 5.5',
		'US M 4.5': 'US W 6',
		'US M 5': 'US W 6.5',
		'US M 5.5': 'US W 7',
		'US M 6': 'US W 7.5',
		'US M 6.5': 'US W 8',
		'US M 7': 'US W 8.5',
		'US M 7.5': 'US W 9',
		'US M 8': 'US W 9.5',
		'US M 8.5': 'US W 10',
		'US M 9': 'US W 10.5',
		'US M 9.5': 'US W 11',
		'US M 10': 'US W 11.5',
		'US M 10.5': 'US W 12',
		'US M 11': 'US W 12.5',
		'US M 11.5': 'US W 13',
		'US M 12': 'US W 13.5',
		'US M 12.5': 'US W 14',
		'US M 13': 'US W 14.5',
		'US M 13.5': 'US W 15',
		'US M 14': 'US W 15.5',
		'US M 14.5': 'US W 16',
		'US M 15': 'US W 16.5',
		'US M 15.5': 'US W 17',
	};
	useEffect(() => {
		const initialSizes = selectedSizes.filter((size) => maleShoeSizes.includes(size));
		setSelectSizes(initialSizes);
	}, [selectedSizes]);

	// State to manage selected sizes
	const isSizeSelected = (size) => selectSizes.includes(size);

	// Handle checkbox change
	const handleCheckboxChange = (size) => {
		setSelectSizes((prev) => {
			const newSizes = prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size];
			setSelectedSizes(newSizes); // Store only men's sizes
			return newSizes;
		});
	};

	return (
		<div className="product__card attributes product__card--info">
			<div className="wrapper-element p-0">
				<header className="card-header">
					<h4>Product More Info</h4>
				</header>
				<main className="card-content relative">
					<form name="product-info" className="form">
						<div className="form-group">
							<label className="label" htmlFor="colorWay">
								<b>ColorWay:</b>
							</label>
							<input value={form.productColorWay || ''} onChange={onChange} name="productColorWay" placeholder="Colorway" type="text" id="colorWay" className={`input  ${formError.productColorWay ? 'error' : ''}`} />
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productFactoryColor">
								<b>Factory color:</b>
							</label>
							<input value={form.productFactoryColor || ''} onChange={onChange} name="productFactoryColor" placeholder="Factory Color" type="text" id="factoryColor" className={`input  ${formError.productFactoryColor ? 'error' : ''}`} />
						</div>
						<div className="form-group">
							<label className="label" htmlFor="style">
								<b>Style/Season:</b>
							</label>
							<input value={form.productStyleSeason || ''} onChange={onChange} name="productStyleSeason" placeholder="Style/Season" type="text" id="style" className={`input  ${formError.productStyleSeason ? 'error' : ''}`} />
						</div>
						<div className="form-group">
							<label className="label" htmlFor="gender">
								<b>Gender:</b>
							</label>
							<select value={form.productGender || ''} onChange={onChange} name="productGender" id="productGender" className={`select ${formError.productGender ? 'error' : ''}`}>
								<option value="" disabled>
									Select a Gender
								</option>
								{genders.map((gender) => (
									<option key={gender._id} value={gender.name}>
										{gender.name}
									</option>
								))}
							</select>
							<p data-cy="" className={`error__onblur ${formError.productGender ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
						<div className="form-group">
							<label className="label" htmlFor="sizes">
								<b>Available Sizes:</b>
							</label>
							{/* <div className="tabs">
								<button type="button" className={`tab-button ${activeTab === 'male' ? 'active' : ''}`} onClick={() => setActiveTab('male')}>
									Men's Sizes
								</button>
								<button type="button" className={`tab-button ${activeTab === 'female' ? 'active' : ''}`} onClick={() => setActiveTab('female')}>
									Women's Sizes
								</button>
							</div> */}
							<div className="checkbox-group">
								{(activeTab === 'male' ? maleShoeSizes : femaleShoeSizes).map((size) => (
									<div key={size} className="item">
										<label>
											<input name="productAvailableSizes" type="checkbox" id={`size-${size}`} checked={isSizeSelected(size)} onChange={() => handleCheckboxChange(size)} />
											<b>{size}</b>
											{activeTab === 'male' && sizeEquivalents[size] && <span className="equivalent-size">(Eq: {sizeEquivalents[size]})</span>}
										</label>
									</div>
								))}
							</div>
						</div>
					</form>
				</main>
			</div>
		</div>
	);
};
