import React, { useState, useEffect, useCallback } from 'react';
import { Wrapper } from '../../../../../components/content';
import { useProducts } from '../../../../../context/products';
import { jwtDecode } from 'jwt-decode';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../../context/useAuth';
import { AXIOS_API_CALL } from '../../../../../utils/endpoint';
import { PERMISSIONS } from '../../../../../utils/permissions';

export const ProductPrice = (props) => {
	const { form, onChange, formError } = useProducts();
	const { className } = props;
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [activeSizeTab, setActiveSizeTab] = useState('');
	const { productAvailableSizes, productGoatPrices, productStockxPrices } = form;

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(token);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	useEffect(() => {
		if (productAvailableSizes.length > 0) {
			setActiveSizeTab(productAvailableSizes[0]);
		}
	}, [productAvailableSizes]);

	// Handle tab switching

	const [tabErrors, setTabErrors] = useState({});

	const hasTabError = (size) => {
		return Object.keys(formError).some((key) => key.includes(`-${size}`) && formError[key]);
	};
	const handleTabSwitch = (size) => {
		setActiveSizeTab(size);
		console.log(activeSizeTab);
		const hasError = Object.keys(formError).some((key) => key.includes(`-${size}`) && formError[key]);
		setTabErrors((prevTabErrors) => ({ ...prevTabErrors, [size]: hasError }));
		console.log(tabErrors);
	};

	const sizeErrorStock = formError.stockxPrices ? formError.stockxPrices.split('for size ')[1] : '';
	const sizeErrorGoat = formError.goatPrices ? formError.goatPrices.split('for size ')[1] : '';

	const handleKeyDown = (e) => {
		if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab') {
			e.preventDefault();
		}
	};

	return (
		<Wrapper className={`product__card ${className}`}>
			<div className="wrapper-element p-0">
				<header className="card-header">
					<h4>Product Prices by Size</h4>
				</header>

				<main className="card-content relative">
					<form name="stockxfee" className="form">
						<div className="form-group">
							<div className="checkbox-group">
								<div className="item">
									<input checked={form.productUseThisShoe || false} onChange={onChange} name="productUseThisShoe" type="checkbox" id="productUseThisShoe" className="checkbox" />
									<label className="label" htmlFor="productUseThisShoe">
										<b>Use this shoe for Stock X API</b>
									</label>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productStockxFee">
								<b>StockX Fee:</b>
							</label>
							<input value={form.productStockxFee || ''} onChange={onChange} name="productStockxFee" placeholder="StockX Fee" type="number" id="productStockxFee" className={`input ${formError.productStockxFee ? 'error' : ''}`} /> <span className="inputHelper">%</span>
							<p data-cy="" className={`error__onblur ${formError.productStockxFee ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
						<div className="form-group">
							<label className="label" htmlFor="productGoatFee">
								<b>Goat Fee:</b>
							</label>
							<input value={form.productGoatFee || ''} onChange={onChange} name="productGoatFee" placeholder="Goat Fee" type="number" id="productGoatFee" className={`input ${formError.productGoatFee ? 'error' : ''}`} /> <span className="inputHelper">$</span>
							<p data-cy="" className={`error__onblur ${formError.productGoatFee ? 'error' : ''}`}>
								* This field is required!
							</p>
						</div>
					</form>
					{productAvailableSizes.length > 0 ? (
						<>
							{/* Tab Navigation - Sizes */}
							<div className="tabs tabs-price">
								{productAvailableSizes.map((size, index) => (
									<button key={index} className={`tab ${activeSizeTab === size ? 'active' : ''} ${sizeErrorStock === size || sizeErrorGoat === size ? 'error' : ''}`} onClick={() => handleTabSwitch(size)}>
										{size}
									</button>
								))}
							</div>

							{/* Tab Content for the active size */}
							<div className="tab-content prices">
								{productAvailableSizes.map((size) => {
									if (size === activeSizeTab) {
										const goatPrice = productGoatPrices.find((price) => price.size === size)?.price || '';
										const stockxPrice = productStockxPrices.find((price) => price.size === size)?.price || '';
										return (
											<div key={size}>
												<form name={`prices-${size}`} className="form">
													{/* GOAT Price */}
													<div className="form-group">
														<label className="label" htmlFor={`goatPrice-${size}`}>
															<b>GOAT Price:</b>
														</label>
														<input value={goatPrice} onChange={onChange} name="productGoatPrices" placeholder="GOAT Price" type="number" pattern="[0-9]*" min="0" id={`goatPrice-${size}`} className={`input ${sizeErrorGoat === size ? 'error' : ''}`} />
														<span className="inputHelper">$</span>
														<p data-cy="" className={`error__onblur ${sizeErrorGoat === size ? 'error' : ''}`}>
															{formError.goatPrices}
														</p>
													</div>

													{/* Stockx Price */}
													<div className="form-group">
														<label className="label" htmlFor={`stockxPrice-${size}`}>
															<b>Stockx Price:</b>
														</label>
														<input value={stockxPrice} onChange={onChange} name="productStockxPrices" placeholder="Stockx Price" type="number" pattern="[0-9]*" id={`stockxPrice-${size}`} className={`input ${sizeErrorStock === size ? 'error' : ''}`} />
														<span className="inputHelper">$</span>
														<p data-cy="" className={`error__onblur ${sizeErrorStock === size ? 'error' : ''}`}>
															{formError.stockxPrices}
														</p>
													</div>
												</form>
											</div>
										);
									}

									return null;
								})}
							</div>
						</>
					) : (
						<p>No sizes available, please select a size in 'Available sizes'</p>
					)}
				</main>
			</div>
		</Wrapper>
	);
};
