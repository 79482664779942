import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data, handleToggle } = props;
	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS
	const { deleteActions, groceryPermissions, getGroceryPermissions } = props;

	console.log(pagination);

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						{/*
						 */}
						<th>Name</th>
						<th>ColorWay</th>
						<th>Product</th>
						<th>Price</th>
						<th>Size</th>
						<th>Product Image</th>
						<th>Status</th>
						<th>Seller</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td>{item.name}</td>
								<td>{item.colorway}</td>
								<td>{item.sku}</td>
								<td>{item.regularPrice}</td>
								<td>{item.size}</td>
								<td>
									<img className="img" src={item.image} alt={item.name} width={120} style={{ borderRadius: '0.25rem' }} />{' '}
								</td>

								<td> {item.status}</td>
								<td>{item.sellerName}</td>
								{/* <td> </td> */}
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
