import { Wrapper } from '../../../../../components/content';
import { useBrands } from '../../../../../context/brands';

export const BrandInfo = (props) => {
  const { form, onChange, formError } = useBrands();
  const { className } = props;
  

  return (
    <Wrapper className={`brands__card ${className}`}>
      <div className="wrapper-element p-0">
        <header className="card-header">
          <h4>Brand Info</h4>
        </header>

        <main className="card-content relative">
          <form name="brand-info" className="form">
            <div className="form-group">
              <label className="label" htmlFor="brandName">
                <b>Brand Name:</b>
              </label>
              <input value={form.brandName || ''} onChange={onChange} name="brandName" placeholder="Enter Brand Name" type="text" id="brandName" className={`input ${formError.brandName ? 'error' : ''}`} />
              <p data-cy="" className={`error__onblur ${formError.brandName ? 'error' : ''}`}>
                * This field is required!
              </p>
            </div>
          </form>
        </main>
      </div>
    </Wrapper>
  );
};
