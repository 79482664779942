import React, { useState, useEffect, useRef, forwardRef } from 'react';

const DropdownInput = forwardRef((props, ref) => {
  const { data, onUpdate, search, onSearch, toggle } = props;

  return (
    <div className="wrapper-select">
      <div ref={ref} className={`form-select-input ${toggle ? 'isActive' : ''}`}>
        <input name="search" value={search || ''} onChange={(e) => onSearch(e.target.value)} type="search" placeholder="Search..." className="input" />
        <div className="list-items">
          {data && data.length > 0 ? (
            data.map((item) => {
              return (
                <span className="item" data-cy="attributes-list-item" key={item._id} onClick={() => onUpdate({ actions: { name: 'ADD' }, payload: { id: item._id } })}>
                  {item.name}
                </span>
              );
            })
          ) : (
            <p className="d-block w-full text-center">No Data.</p>
          )}
        </div>
      </div>
    </div>
  );
});

const DropdownOutput = forwardRef((props, ref) => {
  const { data, onUpdate, onToggle } = props;

  return (
    <>
      <p className="mb-1">Attributes:</p>
      <div ref={ref} className="form-select-output">
        <div className="result">
          {data && data.length > 0 ? (
            data.map((item) => {
              return (
                <span key={item._id} data-cy="selected-attributes-list-item" onClick={() => onUpdate({ actions: { name: 'REMOVE' }, payload: { id: item._id } })} className="badge">
                  <span className="icon">
                    <img src="/assets/icons/times-small.svg" alt="Close" />
                  </span>
                  <span className="text">{item.name}</span>
                </span>
              );
            })
          ) : (
            <p>Attributes</p>
          )}
        </div>
        <button data-cy="toggle-attributes-btn" onClick={onToggle} className="btn" type="button">
          <span className="icon">
            <img src="/assets/icons/search.svg" alt="Search" title="Search" />
          </span>
        </button>
      </div>
    </>
  );
});

const DropdownSelect = (props) => {
  const { data, update, type, toggle, handleOnSelect } = props;

  const [originalData, setOriginalData] = useState([]);
  const [originalUpdate, setOriginalUpdate] = useState([]);

  const inputRef = useRef(null);
  const outputRef = useRef(null);

  // Search input
  const [search, setSearch] = useState('');
  function handleOnSearch(value) {
    setSearch(value);
  }

  // Toggle dropdown
  const [toggleDropdown, setToggleDropdown] = useState(false);
  function handleOnToggle() {
    setToggleDropdown((prevState) => !prevState);
  }

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target) && outputRef.current && !outputRef.current.contains(event.target)) {
      setToggleDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [inputRef, outputRef]);

  const handleOnClick = ({ actions, payload }) => {
    switch (actions.name) {
      case 'ADD':
        const newRecordData = originalData.filter((item) => item._id !== payload.id);
        setOriginalData(newRecordData);

        const newUpdateData = originalData.filter((item) => item._id === payload.id);
        setOriginalUpdate([...originalUpdate, ...newUpdateData]);

        setSearch('');
        break;
      case 'REMOVE':
        const newData = originalUpdate.filter((item) => item._id !== payload.id);
        setOriginalUpdate(newData);

        const newUpdate = originalUpdate.filter((item) => item._id === payload.id);
        setOriginalData([...originalData, ...newUpdate]);

        setSearch('');
        break;
      default:
        console.warn('Dafault of: handleOnClick func!');
    }
  };

  // Component default data
  useEffect(() => {
    if (!!toggle) {
      setOriginalData(data);
      setOriginalUpdate([]);
    }
  }, [toggle, data]);

  // Component update data
  useEffect(() => {
    switch (type) {
      case 'create-category':
        break;
      case 'create-subcategory':
        break;
      case 'update-category':
        const currentUpdate = update.map((item) => item._id);
        const recordData = data.filter((item) => !currentUpdate.includes(item._id));
        const updateData = data.filter((item) => currentUpdate.includes(item._id));

        setOriginalData(recordData);
        setOriginalUpdate(updateData);
        break;
      default:
        console.warn('Dafault of data!');
    }
  }, [toggle, type, update, data]);

  // Get selected item
  useEffect(() => {
    handleOnSelect(originalUpdate);
  }, [originalUpdate]);

  // Filtered data
  const filterRecordData = originalData && originalData.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  // Dropdown Input
  const dropdownInputProps = {
    data: filterRecordData,
    onUpdate: handleOnClick,
    search: search,
    onSearch: handleOnSearch,
    ref: inputRef,
    toggle: toggleDropdown,
    onToggle: handleOnToggle
  };

  // Dropdown Output
  const dropdownOutputProps = {
    data: originalUpdate,
    onUpdate: handleOnClick,
    ref: outputRef,
    toggle: toggleDropdown,
    onToggle: handleOnToggle
  };

  return (
    <div className="form-group form-select">
      <DropdownInput {...dropdownInputProps} />
      <DropdownOutput {...dropdownOutputProps} />
    </div>
  );
};

export default DropdownSelect;
