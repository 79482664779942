import React, { useEffect, useState } from 'react';
import { Section, Col, Row, Wrapper } from '../../../../components/content';
import { BrandProvider, useBrands } from '../../../../context/brands';
import { PERMISSIONS } from '../../../../utils/permissions';
import LoadingPlaceholder from '../../../../components/loadingPlaceholder/LoadingPlaceholder';
import Loading from '../../../../components/loading/Loading';
import { BrandGallery } from './brand-gallery/BrandGallery';
import GalleryUpload from '../../slideshow-page/GalleryUpload';
import { BrandInfo } from './brand-info/BrandInfo';

const CreateBrandLoader = (props) => {
	const { children } = props;
	const { setBrandEdit } = useBrands();

	useEffect(() => {
		setBrandEdit(false);
	}, []);

	return <React.Fragment>{children}</React.Fragment>;
};
const CreateBrandSubmit = () => {
	const { onCancel, onSubmit } = useBrands();
	return (
		<footer className="brand-save">
			<div className="actions">
				<button onClick={onCancel} className="btn btn-primary-outline" type="button">
					<span className="text">Cancel</span>
				</button>
				<button onClick={onSubmit} className="btn btn-primary" type="button">
					<span className="text">Create Brand</span>
				</button>
			</div>
		</footer>
	);
};

const CreateBrand = () => {
	return (
		<BrandProvider>
			<Section className="section__brand section__brand--wrapper section__brand--create">
				<h2 className="page-title">Create Brand</h2>
				<CreateBrandLoader>
					<Row>
						<Col>
							<Wrapper className="side-left">
								<BrandInfo />
							</Wrapper>
						</Col>
						<Col>
							<Wrapper className="side-right">
								<BrandGallery />
							</Wrapper>
						</Col>
					</Row>
				</CreateBrandLoader>

				<CreateBrandSubmit />
			</Section>
		</BrandProvider>
	);
};

export default CreateBrand;
