import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';
import { generateId, slugify, slugifyReplaceAll } from '../../helpers/helpers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { jwtDecode } from 'jwt-decode';

const ProductContext = createContext(null);

const ProductProvider = ({ children }) => {
	// States for product data and attributes
	const [productData, setProductData] = useState({});
	const [productLoading, setProductLoading] = useState(true);
	const [productAttributesData, setProductAttributesData] = useState([]);
	const [productAttributesLoading, setProductAttributesLoading] = useState(false);
	const [formFields, setFormFields] = useState([]);
	const { user } = useAuth();
	const { productId } = useParams();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const navigate = useNavigate();
	const [pageNo, setPageNo] = useState('1');

	// GET USER TOKEN
	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			console.log(permissions);
			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	useEffect(() => {
		if (productData.availableSizes) {
			setSelectedSizes(productData.availableSizes);
		}
	}, [productData]);

	const [form, setForm] = useState({
		productID: '',
		productName: '',
		productFeatured: false,
		productImage: [],
		productBrand: [],
		productColorWay: '',
		productFactoryColor: '',
		productGender: '',
		productStyleSeason: '',
		productAvailableSizes: [],
		productActive: false,
		productNewRelease: false,
		productCategory: [],
		productRetailPrice: '',
		productSku: '',
		productManage: false,
		productStockQuantity: '',
		productReleaseDate: '',
		productStockxPrices: [],
		productGoatPrices: [],
		stockxFee: '',
		productUseThisShoe: false,
		productMiddleman: false,
	});
	const [productAvailableSizes, setProductAvailableSizes] = useState([]);
	const [formError, setFormError] = useState({
		productID: false,
		productName: null,
		productFeatured: false,
		productImage: false,
		productBrand: null,
		productColorWay: false,
		productFactoryColor: false,
		productGender: null,
		productStyleSeason: false,
		productAvailableSizes: false,
		productActive: false,
		productNewRelease: false,
		productCategory: null,
		productRetailPrice: false,
		productSku: false,
		productManage: false,
		productStockQuantity: false,
		productReleaseDate: null,
		productStockxPrices: false,
		productGoatPrices: false,
		productReleaseDate: false,
		productStockxFee: false,
		productGoatFee: false,
		productUseThisShoe: false,
		productMiddleman: false,
	});

	useEffect(() => {
		setFormError(
			productAvailableSizes.reduce((acc, size) => {
				acc[`goatPrice-${size}`] = null;
				acc[`stockxPrice-${size}`] = null;
				return acc;
			}, {})
		);

		console.log(formError);
	}, [productAvailableSizes]);

	const validateForm = () => {
		const errors = {
			productName: !form.productName ? 'Product name is required' : null,
			productBrand: !form.productBrand ? 'Product brand is required' : null,
			productGender: !form.productGender ? 'Product gender is required' : null,
			productSku: !form.productSku ? 'SKU is required' : null,
			productReleaseDate: !form.productReleaseDate ? 'Release date is required' : null,
			productGoatFee: !form.productGoatFee ? 'Goat fee is required' : null,

			productStockxFee: !form.productStockxFee ? 'StockX fee is required' : null,
		};
		const selectedSizes = form.productAvailableSizes;
		const stockxPrices = form.productStockxPrices;
		const goatPrices = form.productGoatPrices;

		selectedSizes.forEach((size) => {
			const stockxPrice = stockxPrices.find((price) => price.size === size)?.price;
			const goatPrice = goatPrices.find((price) => price.size === size)?.price;
			console.log(stockxPrice, goatPrice);
			if (!stockxPrices.find((price) => price.size === size)) {
				errors.stockxPrices = `StockX price is required for size ${size}`;
			}
			if (!goatPrices.find((price) => price.size === size)) {
				errors.goatPrices = `Goat price is required for size ${size}`;
			}
			if (stockxPrice === 0) {
				errors.stockxPrices = `StockX price is required for size ${size}`;
			}
			if (stockxPrice < 0) {
				errors.stockxPrices = `StockX price must be greater than 0 for size ${size}`;
			}
			if (goatPrice === 0) {
				errors.goatPrices = `Goat price is required for size ${size}`;
			}
			if (goatPrice < 0) {
				errors.goatPrices = `Goat price must be greater than 0 for size ${size}`;
			}
		});

		setFormError(errors);
		console.log(errors);
		// Check if there are any errors
		return Object.values(errors).every((error) => error === null);
	};

	const [selectImage, setSelectImage] = useState([]);
	const [selectedSizes, setSelectedSizes] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState({});

	const onCancel = () => {
		navigate(-1);
	};

	// Function to handle form input changes
	const onChange = (e) => {
		const { name, value, type, checked, files, id } = e.target;
		const size = id.split('-')[1]; // Extract size from the id
		if (type === 'number') {
			// Determine which price array to update based on the input name
			const updatedValue = Math.max(0, parseFloat(value) || 0);
			console.log(updatedValue);
			if (updatedValue < 0) {
				return;
			}

			if (name === 'productGoatPrices') {
				setForm((prevForm) => ({
					...prevForm,
					productGoatPrices: prevForm.productGoatPrices.some((price) => price.size === size) ? prevForm.productGoatPrices.map((price) => (price.size === size ? { ...price, price: parseFloat(value) || 0 } : price)) : [...prevForm.productGoatPrices, { size, price: parseFloat(value) || 0 }],
				}));
			} else if (name === 'productStockxPrices') {
				setForm((prevForm) => ({
					...prevForm,
					productStockxPrices: prevForm.productStockxPrices.some((price) => price.size === size) ? prevForm.productStockxPrices.map((price) => (price.size === size ? { ...price, price: parseFloat(value) || 0 } : price)) : [...prevForm.productStockxPrices, { size, price: parseFloat(value) || 0 }],
				}));
			} else {
				setForm((prevForm) => ({
					...prevForm,
					[name]: updatedValue, // Convert to number or default to 0
				}));
			}
		} else if (type === 'checkbox') {
			// For checkboxes, update the state with the checked value
			console.log(checked, e.target);
			setForm((prevForm) => ({
				...prevForm,
				[name]: checked,
			}));
		} else if (type === 'file') {
			// For file inputs, handle file selection
			if (files && files.length > 0) {
				const file = files[0];
				// Assuming you want to handle only one file, update the form field
				setForm((prevForm) => ({
					...prevForm,
					[name]: file,
				}));
			}
		} else if (type === 'number') {
			setForm((prevForm) => ({
				...prevForm,
				[name]: parseFloat(value) || 0, // Convert to number or default to 0
			}));
		} else if (type === 'select-one') {
			// For select inputs, update the state with the selected value
			console.log(value);
			setForm((prevForm) => ({
				...prevForm,
				[name]: value,
			}));
		} else {
			// For text inputs, select inputs, etc., update the state with the value
			setForm((prevForm) => ({
				...prevForm,
				[name]: value,
			}));
		}
	};

	useEffect(() => {
		if (productData) {
			console.log(productData, form);

			setForm((prevForm) => ({
				...prevForm,
				productID: productData._id || prevForm.productID,
				productName: productData.name || prevForm.productName,
				productFeatured: productData.futureRelease || prevForm.productFeatured,
				productImage: selectImage.length > 0 ? selectImage : productData.images, // Keep existing images
				productBrand: selectedBrand || prevForm.productBrand,
				productColorWay: productData.colorway || prevForm.productColorWay,
				productFactoryColor: productData.factoryColor || prevForm.productFactoryColor,
				productGender: productData.gender || prevForm.productGender,
				productStyleSeason: productData.styleSeason || prevForm.productStyleSeason,
				productAvailableSizes: selectedSizes || prevForm.productAvailableSizes, // Keep existing sizes
				productActive: productData.active || prevForm.productActive,
				productNewRelease: productData.isNewRelease || prevForm.productNewRelease,
				productCategory: productData.category || prevForm.productCategory,
				productRetailPrice: productData.retailPrice || prevForm.productRetailPrice,
				productSku: productData.sku || prevForm.productSku,
				productManage: prevForm.productManage,
				productStockQuantity: prevForm.productStockQuantity,
				productReleaseDate: productData.releaseDate || prevForm.productReleaseDate,
				productStockxPrices: productData.stockxPrices || prevForm.productStockxPrices,
				productGoatPrices: productData.goatPrices || prevForm.productGoatPrices,
				productStockxFee: productData.stockxFee || prevForm.productStockxFee,
				productGoatFee: productData.goatFee || prevForm.productGoatFee,
				productUseThisShoe: productData.usedForStockxAPI || prevForm.productUseThisShoe,
				productMiddleman: productData.useMiddleman || prevForm.productMiddleman,
			}));
			setSelectedBrand(productData.brand);
		}
		console.log(form);
	}, [productData, selectImage, selectedSizes]);

	const updatePricesBasedOnSelectedSizes = () => {
		const filteredGoatPrices = form.productGoatPrices.filter((price) => selectedSizes.includes(price.size));
		const filteredStockxPrices = form.productStockxPrices.filter((price) => selectedSizes.includes(price.size));

		setForm((prevForm) => ({
			...prevForm,
			productGoatPrices: filteredGoatPrices,
			productStockxPrices: filteredStockxPrices,
		}));

		// Return filtered prices
		return { filteredGoatPrices, filteredStockxPrices };
	};

	useEffect(() => {
		updatePricesBasedOnSelectedSizes();
	}, [selectedSizes]);

	// On Submit
	const onUpdate = useCallback(async () => {
		if (!validateForm()) return;
		const { token } = user; // Ensure `user` is defined in your context or props
		const { filteredGoatPrices, filteredStockxPrices } = updatePricesBasedOnSelectedSizes();
		console.log(form);
		const productUpdatePayload = {
			_id: productData._id,
			name: form.productName || productData.name,
			futureRelease: productData.futureRelease,
			images: form.productImage,
			brand: form.productBrand,
			factoryColor: form.productFactoryColor || productData.factoryColor,
			colorway: form.productColorWay || productData.colorway,
			gender: form.productGender || productData.gender,
			styleSeason: form.productStyleSeason || productData.styleSeason,
			availableSizes: selectedSizes,
			active: form.productActive,
			isNewRelease: form.productNewRelease,
			category: '669f89119e5fc40ff18b8b78',
			retailPrice: 120,
			sku: form.productSku || productData.sku,
			releaseDate: form.productReleaseDate,
			oneSizeFitsAll: false,
			attachments: productData.attachments,
			keywords: productData.keywords,
			attributes: productData.attributes,
			stockxPrices: filteredStockxPrices, // Use filtered prices
			goatPrices: filteredGoatPrices,
			stockxFee: form.productStockxFee || productData.stockxFee,
			goatFee: form.productGoatFee || productData.goatFee,
			usedForStockxAPI: form.productUseThisShoe,
			useMiddleman: form.productMiddleman,
		};
		console.log(productUpdatePayload);
		const updatedProduct = {
			...productUpdatePayload,
			productID: productData._id, // Assuming productID should not be changed
		};

		await axios
			.put(
				`${SERVER_URL}/${AXIOS_API_CALL.updateProduct}/${productData._id}`,
				{ ...updatedProduct },
				{
					withCredentials: false,
					headers: { Authorization: `Bearer ${token}` },
				}
			)
			.then((res) => {
				if (res.status === 200) {
					notification.success({ message: 'Product updated successfully!' });
					setProductData(res.data);
					setTimeout(() => {
						console.log(`/admin/shoes/products?page=${pageNo}`);
					}, 1500);
					const savedPage = localStorage.getItem('currentPage');
					navigate(-1);
				}
			})
			.catch((err) => {
				console.error('Error updating product:', err);
				if (err.response && err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to update product.' });
				}
			})
			.finally(() => {});
	}, [form, productData._id, user]);

	const onSubmit = useCallback(async () => {
		console.log('test', validateForm());
		if (!validateForm()) return;

		const { token } = user; // Ensure `user` is defined in your context or props
		const productCreatePayload = {
			name: form.productName,
			futureRelease: false,
			images: selectImage,
			brand: form.productBrand,
			factoryColor: form.productFactoryColor,
			colorway: form.productColorWay,
			gender: form.productGender,
			styleSeason: form.productStyleSeason,
			availableSizes: selectedSizes,
			active: form.productActive,
			isNewRelease: form.productNewRelease,
			category: '669f89119e5fc40ff18b8b78',
			retailPrice: 120,
			sku: form.productSku,
			releaseDate: form.productReleaseDate,
			oneSizeFitsAll: false,
			attachments: [],
			keywords: [],
			attributes: [],
			stockxPrices: form.productStockxPrices,
			goatPrices: form.productGoatPrices,
			stockxFee: form.productStockxFee,
			goatFee: form.productGoatFee,
			usedForStockxAPI: form.productUseThisShoe,
			useMiddleman: form.productMiddleman,
		};
		console.log(productCreatePayload);
		await axios
			.post(`${SERVER_URL}/${AXIOS_API_CALL.createProduct}`, productCreatePayload, {
				withCredentials: false,
				headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				if (res.status === 201) {
					notification.success({ message: 'Product created successfully!' });
					setProductData(res.data);
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 409) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				}
				if (err.response && err.response.status === 403) {
					notification.error({ message: err.response.data.error || 'Request Timeout' });
				} else {
					notification.error({ message: 'Failed to create product.' });
				}

				console.error('Error updating product:', err);
			})
			.finally(() => {});
	}, [form, productData._id, user]);

	// Function to fetch product data
	const getProduct = useCallback(
		async (productId) => {
			const { token } = user; // Ensure `user` is defined in your context or props
			try {
				setProductLoading(true);
				const res = await axios.get(
					`${SERVER_URL}/${AXIOS_API_CALL.getProduct}/${productId}`,

					{ withCredentials: false, headers: { Authorization: `Bearer ${token}` } }
				);
				if (res.status === 200) {
					setProductData(res.data);
					// Additional data handling if needed
				}
			} catch (err) {
				console.error(err);
			} finally {
				setProductLoading(false);
			}
		},
		[user]
	);

	// Function to generate form fields
	const generateFormFields = () => {
		// Your logic for generating form fields
	};

	// Function to handle form submission or updates
	const handleOnSave = (data) => {
		// Your logic for saving product data
	};

	const values = useMemo(
		() => ({
			productData,
			productLoading,
			productAttributesData,
			productAttributesLoading,
			formFields,
			generateFormFields,
			handleOnSave,
			form,
			formError,
			getProduct,
			productId,
			onCancel,
			onUpdate,
			setSelectImage,
			onChange,
			onSubmit,
			setSelectedSizes,
			selectedSizes,
			setForm,
			setSelectedBrand,
			selectedBrand,
			setPageNo,
		}),
		[productData, productLoading, productAttributesData, productAttributesLoading, formFields, form, formError, productId, selectedSizes, setForm, setSelectedBrand, selectedBrand, setPageNo]
	);

	return <ProductContext.Provider value={values}>{children}</ProductContext.Provider>;
};

const useProducts = () => {
	return useContext(ProductContext);
};

export { ProductProvider, useProducts };
