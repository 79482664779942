import React, { useState, useEffect } from 'react';
import { Wrapper } from '../../../../../components/content';
import GalleryUpload from '../../../slideshow-page/GalleryUpload';
import { useProducts } from '../../../../../context/products';

export const ProductGallery = (props) => {
	const { className } = props;
	const { productData } = useProducts();
	const [imageData, setImageData] = useState('');
	const imageProps = {
		type: 'product',
		slideshow: false,
		navigate: '',
		multiple: false,
	};


	useEffect(() => {
		if (productData.images && productData.images.length > 0) {
			// Assuming you want to display the first image from the product data
			setImageData(productData.images[0].url);
		}
	}, [productData.images]);

	const handleImageUpload = (image) => {
		setImageData(image); // Update state
	};
	const handleRemoveImage = () => {
		setImageData(''); // Clear the image data
	};
	return (
		<>
			<Wrapper className={`product__card ${className}`}>
				<header className="card-header">
					<h4>Product Image</h4>
				</header>
				<div className="update-gallery">
					{imageData && (
						<>
							<img src={imageData} alt="Uploaded brand logo" width={300} height={120} style={{ border: 'dashed 1px gray', objectFit: 'contain', margin: '8px' }} />
							<button className="ant-btn btn-primary-outline" onClick={handleRemoveImage}>
								Remove image
							</button>
						</>
					)}

					<GalleryUpload props={imageProps} onImageUpload={handleImageUpload} />
				</div>
			</Wrapper>
		</>
	);
};
