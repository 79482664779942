import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/useAuth';
import Overlay from '../overlay/Overlay';
import { Wrapper, Aside } from '../content';
import Navbar from '../navbar/Navbar';
import { jwtDecode } from 'jwt-decode';
import { PERMISSIONS } from '../../utils/permissions';
import { groceryMenuItems } from '../../utils/navigations';

const Sidebar = (props) => {
	const { onHide, onToggle, active, className } = props;
	const { user, logout } = useAuth();
	const location = useLocation();

	const [groceryPermissions, setGroceryPermissions] = useState([]);
	console.log(user.token);
	// GET USER TOKEN
	useEffect(() => {
		if (user && user.token) {
			const token = user.token;
			const decodeToken = jwtDecode(token);

			if (decodeToken && decodeToken.roleData && decodeToken.roleData.permissions) {
				const permissions = decodeToken.roleData.permissions;
        console.log(permissions)

				if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
					if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
						setGroceryPermissions(permissions.grocery);
					}
				}
			}
		}
	}, [user, location]);

	// LOGOUT
	const handleLogout = () => {
		logout();
		onHide(false);
	};

	useEffect(() => {
		active ? document.documentElement.classList.add('overflow-hidden') : document.documentElement.classList.remove('overflow-hidden');
	}, [active]);

	const navbarProps = {
		location: location,
	};

	const groceryProps = {
		onHide: onHide,
		handleLogout: handleLogout,
		groceryPermissions: groceryPermissions,
	};

	// GROCERY Menus
	const groceryMenus = groceryMenuItems(groceryProps);

	return (
		<>
			<Aside className={`gl__sidebar gl__sidebar--left gl__sidebar--layout ${active ? 'isActive' : ''} ${className !== undefined ? className : ''}`}>
				<Wrapper className="logo">
					<Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`} className="link d-block" onClick={() => onHide(false)} data-cy="sidebar-logo">
						<img className="logo-brand" alt="Benjamin" src="/assets/images/logo-brand.svg" width={200} />
					</Link>
				</Wrapper>

				<Navbar className="navbar" {...navbarProps} menus={{ grocery: groceryMenus }} />
			</Aside>

			<Overlay onToggle={onToggle} active={active} />
		</>
	);
};

export default Sidebar;
