import React from 'react';

const title = {
	color: '#777',
	fontWeight: '100',
	fontSize: '36px',
	textAlign: 'center',
	paddingBlock: '20px',
};

const UnderConstruction = () => {
	return (
		<>
			<div className="section__not-found-error">
				<div className="section__content p-4 bg-red">
					<h1 style={title}>Under construction</h1>
				</div>
			</div>
		</>
	);
};

export default UnderConstruction;
