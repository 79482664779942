import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';
import { jwtDecode } from 'jwt-decode';
import { Select, Input, Button, Form, Row, Col, Checkbox, notification } from 'antd';
import SidebarModal from '../../components/sidebarModal/SidebarModal';
import Loading from '../../components/loading/Loading';
import LoadingPlaceholder from '../../components/loadingPlaceholder/LoadingPlaceholder';
import { Section, Main, Wrapper } from '../../components/content';
import { stateUS } from '../../helpers/state';
import Table from './Table';
import SelectCustom from '../../components/customSelect/CustomSelect';
import { notificationMessages } from '../../helpers/notificationMessages';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';
import UnderConstruction from '../grocery/block-list-page/UnderConstruction';

const { Option } = Select;
const options = [5, 10, 20, 50, 100];

const Profile = () => {
	const { user } = useAuth();
	const location = useLocation();

	const [currentPage, setCurrentPage] = useState(1);
	const [limitPage, setLimitPage] = useState(10);
	const [listActivity, setListActivity] = useState([]);
	const [listRefetch, setListRefetch] = useState(false);
	const [loadingActivity, setLoadingActivity] = useState(true);
	const [listDownloadId, setListDownloadId] = useState({ id: '' });

	// READ USER ROLE NAME FROM STORAGE - DECODE
	const [userRoleName, setUserRoleName] = useState('');

	// GET USER ROLE NAME FROM STORAGE - DECODE
	useEffect(() => {
		if (user && user.token) {
			const decodeToken = jwtDecode(user.token);

			if (decodeToken && decodeToken.roleData) {
				setUserRoleName(decodeToken.roleData.roleName);
			}
		}
	}, [user, location]);

	// LOADER PROTECTION
	const [editBtnLoading, setEditBtnLoading] = useState(false);

	// SEARCH
	const [search, setSearch] = useState(null);
	const [searchForm, setSearchForm] = useState({ query: '' });

	const handleOnSubmitSearch = (e) => {
		e.preventDefault();
		setSearch(searchForm.query);
		setListRefetch((prevState) => !prevState);
	};

	const handleOnChangeSearch = (value) => {
		setSearchForm({ query: value });
	};

	const handleOnClearSearch = () => {
		setSearch(null);
		setSearchForm({ query: '' });
		setListRefetch((prevState) => !prevState);
	};

	// READ
	const [userData, setUserData] = useState([]);
	const [loadingUserData, setLoadingUserData] = useState(true);

	// UPDATE
	const updateModalFormRef = useRef(null);
	const [toggleUpdate, setToggleUpdate] = useState(false);
	const [updateUserData, setUpdateUserData] = useState([]);
	const [loadingUpdate, setLoadingUpdate] = useState(true);
	const [updateRefecth, setUpdateRefetch] = useState(false);
	const [updateUserId, setUpdateUserId] = useState({ id: '' });

	// UPDATE TOGGLE PASSWORD
	const [toggleNewPassword, setToggleNewPassword] = useState(false);
	const onChange = (e) => {
		if (e.target.checked) {
			setToggleNewPassword(true);
		} else {
			setToggleNewPassword(false);
		}
	};

	// READ - USER DATA
	useEffect(() => {
		const userId = user.id;
		console.log(user);
		const { token } = user;

		if (!!userId) {
			try {
				setLoadingUserData(true);
				axios
					.post(`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmployee}/${userId}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
					.then((res) => {
						setUserData(res.data);
						setTimeout(() => {
							setLoadingUserData(false);
						}, 700);
					})
					.catch((err) => console.error(err))
					.finally(() => {});
			} catch (err) {
				console.error(err);
				setLoadingUserData(false);
			}
		}
	}, [user, setUserData, setLoadingUserData, updateRefecth]);

	// UPDATE - USER DATA
	useEffect(() => {
		const userId = updateUserId.id;
		const { token } = user;

		if (!!userId) {
			try {
				setLoadingUpdate(true);
				axios
					.post(`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmployee}/${userId}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
					.then((res) => {
						setUpdateUserData(res.data);
						setTimeout(() => {
							setLoadingUpdate(false);
						}, 700);
					})
					.catch((err) => console.error(err))
					.finally(() => {});
			} catch (err) {
				console.error(err);
				setLoadingUpdate(false);
			}
		}
	}, [user, setLoadingUpdate, setUpdateUserData, updateUserId]);

	// READ - ACTIVITY OF USER
	useEffect(() => {
		const userId = user.id;
		const { token } = user;

		if (!!userId) {
			try {
				setLoadingActivity(true);
				axios
					.post(`${SERVER_URL}/${AXIOS_API_CALL.employeeLogs}/${currentPage}/${limitPage}/${search || null}`, { id: userId, department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
					.then((res) => {
						setListActivity(res.data);
						setTimeout(() => {
							setLoadingActivity(false);
						}, 700);
					})
					.catch((err) => console.error(err))
					.finally(() => {});
			} catch (err) {
				console.error(err);
				setLoadingActivity(false);
			}
		}
	}, [user, currentPage, limitPage, search, listRefetch, updateRefecth]);

	useEffect(() => {
		const userId = user.id;
		if (!!userId) {
			setCurrentPage(1);
		}
	}, [user, updateRefecth]);

	useEffect(() => {
		if (!!search) {
			setCurrentPage(1);
		}
	}, [search]);

	const [downloadFileUrl, setDownloadFileUrl] = useState({
		url: '',
	});

	useEffect(() => {
		const listId = listDownloadId.id;
		const { token } = user;

		if (!!listId) {
			// prepare all for download
			try {
				axios
					.post(`${SERVER_URL}/${AXIOS_API_CALL.logsCreateFile}`, { id: listId, department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
					.then((res) => {
						setDownloadFileUrl({ url: res.data.message.downloadPath });
					})
					.finally(setTimeout(() => {}, 700));
			} catch (err) {}
		}
	}, [user, listDownloadId]);

	useEffect(() => {
		const url = downloadFileUrl.url;
		const { token } = user;

		if (!!url) {
			try {
				axios
					.get(`${SERVER_URL}/${AXIOS_API_CALL.logsDownload}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
					.then((res) => {
						const url = window.URL.createObjectURL(new Blob([res.data]));
						const link = document.createElement('a');

						link.href = url;
						link.setAttribute('download', `activity-log-name.txt`);

						document.body.appendChild(link);

						link.click();

						link.parentNode.removeChild(link);

						setDownloadFileUrl({ url: '' });
					})
					.finally(setTimeout(() => {}, 700));
			} catch (err) {}
		}
	}, [user, downloadFileUrl]);

	// UPDATE - TOGGLE MODAL
	const handleToggle = (props) => {
		const { name, state, data } = props;

		switch (name) {
			case 'update':
				setUpdateUserId({ id: user.id });
				state.onToggle((prevState) => !prevState);
				break;
			case 'download':
				setListDownloadId({ id: data });
				break;
			default:
				console.error('handleToggle ---- error');
		}
	};

	// UPDATE - SUBMIT
	const handleOnFinish = async (props) => {
		const { name, data } = props;

		const { token } = user;

		switch (name) {
			case 'update':
				setEditBtnLoading(true);
				try {
					const newPassword = Object.keys(data).includes('newPassword');
					const userId = updateUserId.id;

					let updateData = [];

					// Date to backend --
					if (!newPassword) {
						updateData = updateData = {
							firstName: data?.firstName || '',
							lastName: data?.lastName || '',
							address: [
								{
									address: data?.address || '',
									zip_code: data?.zip_code || '',
									state: data?.state || '',
									city: data?.city || '',
								},
							],
							phone: data?.phone || '',
							email: data?.email || '',
							department: PERMISSIONS.grocery,
						};
					} else {
						updateData = updateData = {
							firstName: data?.firstName || '',
							lastName: data?.lastName || '',
							password: data?.newPassword || '',
							address: [
								{
									address: data?.address || '',
									zip_code: data?.zip_code || '',
									state: data?.state || '',
									city: data?.city || '',
								},
							],
							phone: data?.phone || '',
							email: data?.email || '',
							department: PERMISSIONS.grocery,
						};
					}

					await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.updateEmployee}/${userId}`, { ...updateData }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

					setUpdateRefetch((prevState) => !prevState);
					setToggleUpdate(false);
					setUpdateUserData([]);
					setUpdateUserId({ id: '' });

					setTimeout(() => {
						updateModalFormRef.current.resetFields();
					}, 750);

					// notification
					notification.success({
						message: notificationMessages.successUpdateProfile,
						placement: 'bottomLeft',
					});
					setTimeout(() => {
						setEditBtnLoading(false);
					}, 700);
				} catch (err) {}
				break;
			default:
				console.error('handleOnFinish ---- error');
		}
	};

	// UPDATE
	const updateProps = {
		active: toggleUpdate,
		onToggle: setToggleUpdate,
		formRef: updateModalFormRef,
		loading: loadingUpdate,
	};

	// TABLE
	const tableActionsProps = {
		handleToggle: handleToggle,
	};

	// PAGINATION
	const paginationProps = {
		current: currentPage,
		setCurrentPage: setCurrentPage,
		limitPage: limitPage,
		buttonLimit: 3,
		pagesCount: listActivity.data?.pagesCount,
		totalCount: listActivity.data?.totalCount,
	};

	// FORMS
	const updateFormFields = [
		{
			name: 'firstName',
			value: (updateUserData && updateUserData?.firstName) || '',
		},
		{
			name: 'lastName',
			value: (updateUserData && updateUserData?.lastName) || '',
		},
		{
			name: 'email',
			value: (updateUserData && updateUserData?.email) || '',
		},
		{
			name: 'address',
			value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].address) || '',
		},
		{
			name: 'zip_code',
			value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].zip_code) || '',
		},
		{
			name: 'state',
			value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].state) || '',
		},
		{
			name: 'city',
			value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].city) || '',
		},
		{
			name: 'phone',
			value: (!!updateUserData?.phone?.[0] && updateUserData.phone?.[0]) || '',
		},
		{
			name: 'newPassword',
			value: '',
		},
	];

	return (
		<>
			<h2 className="page-title">Profile</h2>
			<Section className="section__wrapper section__profile">
				<UnderConstruction/>

				{/* Update Profile - all settings */}
				{/* <SidebarModal {...updateProps} className="sidebar__modal--right" title="Edit Profile">
					{!loadingUpdate ? (
						<Form name="update" className="form form-update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
							<Form.Item name="firstName" label="First Name:" rules={[{ required: true, message: 'Please input your first name!' }]}>
								<Input placeholder="First Name:" data-cy="modal-profile-user-firstName" />
							</Form.Item>

							<Form.Item name="lastName" label="Last Name:" rules={[{ required: true, message: 'Please input your last name!' }]}>
								<Input placeholder="Last Name:" data-cy="modal-profile-user-lastName" />
							</Form.Item>

							<Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Please input your email!' }]}>
								<Input placeholder="Email:" value="" data-cy="modal-profile-user-email" />
							</Form.Item>

							<Form.Item name="address" label="Address" rules={[{ required: false, message: 'Please input your address!' }]}>
								<Input placeholder="Address:" data-cy="modal-profile-user-address" />
							</Form.Item>

							<Form.Item name="zip_code" label="Zip Code" rules={[{ required: false, message: 'Please input your zip code!' }]}>
								<Input placeholder="Zip Code:" data-cy="modal-profile-user-zipCode" />
							</Form.Item>

							<Form.Item name="state" label="State" rules={[{ required: false, message: 'Please input your state!' }]}>
								<Select data-cy="modal-profile-user-state">
									<Option value="">Select State</Option>

									{stateUS.map((item) => {
										return (
											<Option key={item.name} value={item.abbreviation}>
												{item.name}
											</Option>
										);
									})}
								</Select>
							</Form.Item>

							<Form.Item name="city" label="City" rules={[{ required: false, message: 'Please input your city!' }]}>
								<Input placeholder="City:" data-cy="modal-profile-user-city" />
							</Form.Item>

							<Form.Item name="phone" label="Phone" rules={[{ required: false, message: 'Please input your phone!' }]}>
								<Input placeholder="Phone:" data-cy="modal-profile-user-phone" />
							</Form.Item>

							<Form.Item className="initial-height">
								<Checkbox onChange={onChange} checked={toggleNewPassword} data-cy="modal-profile-user-toggleNewPassword">
									Create new password
								</Checkbox>
							</Form.Item>

							{toggleNewPassword === true && (
								<Form.Item name="newPassword" label="New Password:" rules={[{ required: true, message: 'Please input your password!' }]}>
									<Input.Password placeholder="Password:" value="" data-cy="modal-profile-user-newPassword" />
								</Form.Item>
							)}

							<span className="fake-label">
								<b>Roles:</b>
							</span>

							<div className="group">
								<span className="label">Role:</span>
								<span className="text" data-cy="modal-profile-user-role">
									{userRoleName}
								</span>
							</div>

							<span className="fake-label">
								<b>Status:</b>
							</span>

							<div className="group">
								<span className="label">Status:</span>
								<span className={`text badge ${userData && userData.status === 'ACTIVE' ? 'active' : 'inactive'}`} data-cy="modal-profile-user-status">
									{userData && userData.status}
								</span>
							</div>

							<Form.Item wrapperCol={{ offset: 8 }}>
								<Row gutter={16}>
									<Col span={12}>
										<Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)} data-cy="modal-profile-cancel-btn">
											<span>Cancel</span>
										</Button>
									</Col>
									<Col span={12}>
										{!editBtnLoading ? (
											<Button type="submit" htmlType="submit" className="btn btn-primary btn-block" data-cy="modal-profile-update-btn">
												<span>Edit Profile</span>
											</Button>
										) : (
											<Button type="button" className="btn btn-primary-outline btn-block">
												<span>Editing...</span>
											</Button>
										)}
									</Col>
								</Row>
							</Form.Item>
						</Form>
					) : (
						<Loading />
					)}
				</SidebarModal> */}
			</Section>
		</>
	);
};

export default Profile;
