import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const Table = (props) => {
	// TABLE
	const { data, groceryPermissions, getGroceryPermissions, handleToggle, viewActions, verifyActions } = props;
	// PAGINATION
	const { pagination } = props;
	// CUSTOM FOR ACTIONS

	return (
		<>
			<table className="table">
				<thead className="thead">
					<tr>
						<th>User</th>
						<th>ID</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody className="tbody">
					{data && data.length > 0 ? (
						data.map((item, idx) => (
							<tr key={item._id}>
								<td>{item.uploadedBy && item.uploadedBy.length > 0 ? `${item.uploadedBy[0].firstName} ${item.uploadedBy[0].lastName}` : 'Unknown User'}</td>
								<td>{item._id}</td>
								<td>
									{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateProducts, PERMISSIONS.deleteProducts, PERMISSIONS.readProducts].includes(key.name)) && (
										<Popover
											className="tbody-actions"
											placement="left"
											trigger="click"
											content={() => (
												<div className="actions" data-cy="actions-popover-products">
													{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && (
														<button className="btn btn-actions" type="button" data-cy="actions-delete-product" onClick={() => handleToggle({ name: 'view', state: viewActions, data: item.uploadedBy.length > 0 ? `${item.uploadedBy[0]._id}` : null })}>
															<span className="icon d-flex">
																<img alt="EDIT" src="/assets/icons/eye.svg" style={{ display: 'block' }} />
															</span>
															<span className="text">VIEW</span>
														</button>
													)}
													{getGroceryPermissions(groceryPermissions, PERMISSIONS.updateProducts) && (
														<button className="btn btn-actions" type="button" data-cy="actions-delete-product" onClick={() => handleToggle({ name: 'verify', state: verifyActions, data: item })}>
															<span className="icon d-flex">
																<img alt="EDIT" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
															</span>
															<span className="text">VERIFY</span>
														</button>
													)}
												</div>
											)}
										>
											<button className="btn" type="button" data-cy="actions-toggle-products">
												<span className="icon">
													<img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
												</span>
											</button>
										</Popover>
									)}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={20} style={{ width: '100%' }}>
								<Message className="content-message">
									<h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
								</Message>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{data && data.length > 0 && <Pagination {...pagination} />}
		</>
	);
};

export default Table;
