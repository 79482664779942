import React from 'react';
import Modal from '../../../../components/modal/Modal';
import LoadingSpinnerButton from '../../../../components/loadingSpinnerButton/LoadingSpinnerButton';

export const ImportNetSuiteModal = ({ importNetSuiteProps, handleOnSubmit, showImportNetSuiteLoader, setToggleImportNetSuite }) => {
  return (
    <Modal {...importNetSuiteProps} className="sidebar__modal--center" title="">
      <form name="importNetSuite" onSubmit={(event) => handleOnSubmit({ event: event, name: 'importNetSuite' })}>
        <h2 className="text-center mb-4">Are you sure you want to import from NetSuite ?</h2>

        <div className="form-group form-group-modal mb-4">
          {!showImportNetSuiteLoader ? (
            <button data-cy="modal-import-netsuite-submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">Yes</span>
            </button>
          ) : (
            <button data-cy="modal-import-netsuite-submit-btn" type="button" className="btn btn-primary-link">
              <span className="text relative" style={{ fontSize: 'smaller' }}>
                Importing
                <LoadingSpinnerButton className="loader-button" />
              </span>
            </button>
          )}

          <button data-cy="modal-import-netsuite-cancel-btn" type="button" className="btn btn-primary" onClick={() => setToggleImportNetSuite(false)}>
            <span className="text">No</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
