import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import Loading from '../../../../components/loading/Loading';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../context/useAuth';
import { PERMISSIONS } from '../../../../utils/permissions';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import axios from 'axios';
import { SERVER_URL } from '../../../../config/index';

export const ViewProduct = ({ viewProps, viewLoading, viewData, handleClose }) => {
	const { user } = useAuth();
	const location = useLocation();
	const [groceryPermissions, setGroceryPermissions] = useState([]);
	const [productDetails, setProductDetails] = useState(null); // State to store product data
	const [readDataLoading, setReadDataLoading] = useState(true);
	if (!viewData) return <p>No product data available</p>;
	

	useEffect(() => {
		const { token } = user || {};
		if (token) {
			const decodedToken = jwtDecode(token);
			const permissions = decodedToken?.roleData?.permissions;
			

			if (permissions && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
				if (Object.keys(permissions).some((permission) => permission.includes('grocery'))) {
					setGroceryPermissions(permissions.grocery || []);
				}
			}
		}
	}, [user, location]);

	useEffect(() => {
		const getReadData = async () => {
			const { token } = user;
			try {
				const response = await axios.get(`${SERVER_URL}/${AXIOS_API_CALL.getProduct}/${viewData}`, {
					withCredentials: false,
					headers: { Authorization: `Bearer ${token}` },
				});
				setProductDetails(response.data);
			} catch (err) {
				console.error('Error fetching data:', err);
			} finally {
				setReadDataLoading(false);
			}
		};

		if (viewData) {
			getReadData();
		}
	}, [viewData, user]);

	if (readDataLoading) return <Loading />; // Show loading indicator while fetching

	if (!productDetails) return <p>No product data available</p>;

	const { name = '', brand = [], category = [], colorway = '', factoryColor = '', styleSeason = '', sku = '', gender = '', retailPrice = '', fee = '', releaseDate = '', images = [], active = false, futureRelease = false, goatPrices = [], stockxPrices = [], stockxFee = '', usedForStockxAPI = false } = productDetails;

	const brandName = brand.name || 'No brand';
	const imageUrl = images.length > 0 ? images[0].url : '';
	const goatPricesMap = goatPrices.map(({ size, price }) => (
		<div key={`goat-${size}`} className="price-detail">
			For {size} = ${price}
		</div>
	));

	const stockxPricesMap = stockxPrices.map(({ size, price }) => (
		<div key={`stockx-${size}`} className="price-detail">
			For {size} = ${price}
		</div>
	));

	return (
		<SidebarModal {...viewProps} className="sidebar__modal--right" title="View Product Details">
			{!viewLoading ? (
				<div className="product-details">
					<h4 className="product-name">{name}</h4>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewBrand">
							<b>Brand:</b>
						</label>
						<p id="viewBrand" className="value viewValue">
							{brandName}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewCategory">
							<b>Category:</b>
						</label>
						<p id="viewCategory" className="value viewValue">
							{category.length > 0 ? category[0].name : 'No category'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewColorway">
							<b>Colorway:</b>
						</label>
						<p id="viewColorway" className="value viewValue">
							{colorway || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewFactoryColor">
							<b>Factory Color:</b>
						</label>
						<p id="viewFactoryColor" className="value viewValue">
							{factoryColor || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStyleSeason">
							<b>Style Season:</b>
						</label>
						<p id="viewStyleSeason" className="value viewValue">
							{styleSeason || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewSku">
							<b>SKU:</b>
						</label>
						<p id="viewSku" className="value viewValue">
							{sku || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewGender">
							<b>Gender:</b>
						</label>
						<p id="viewGender" className="value viewValue">
							{gender || ''}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewReleaseDate">
							<b>Release Date:</b>
						</label>
						<p id="viewReleaseDate" className="value viewValue">
							{new Date(releaseDate).toLocaleDateString() || ''}
						</p>
					</div>

					{images && images.length > 0 && (
						<div className="form-group">
							<label className="label mb-1 d-block" htmlFor="viewImage">
								<b>Image:</b>
							</label>
							<img id="viewImage" src={images[0].url} alt={images[0].altTitle || 'Image'} width="400" className="product-image" />
						</div>
					)}

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewActive">
							<b>Active:</b>
						</label>
						<p id="viewActive" className="value viewValue">
							{active ? 'Yes' : 'No'}
						</p>
					</div>

					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewFutureRelease">
							<b>Future Release:</b>
						</label>
						<p id="viewFutureRelease" className="value viewValue">
							{futureRelease ? 'Yes' : 'No'}
						</p>
					</div>
					{/* Display GOAT Prices */}
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewGoatPrices">
							<b>GOAT Prices:</b>
						</label>
						<p id="viewGoatPrices" className="value viewValue">
							{goatPrices.length > 0 ? goatPricesMap : <p>No GOAT prices available</p>}
						</p>
					</div>

					{/* Display StockX Fee */}
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStockxFee">
							<b>StockX Fee:</b>
						</label>
						<p id="viewGoatFee" className="value viewValue">
							{stockxFee ? stockxFee : <p>No StockX prices available</p>}
						</p>
					</div>

					{/* Display StockX Prices */}
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewStockxPrices">
							<b>StockX Prices:</b>
						</label>
						<p id="viewGoatPrices" className="value viewValue">
							{stockxPrices.length > 0 ? stockxPricesMap : <p>No StockX prices available</p>}
						</p>
					</div>
					<div className="form-group">
						<label className="label mb-1 d-block" htmlFor="viewUsedForStockxAPI">
							<b>Used for Stock x API:</b>
						</label>
						<p id="viewUsedForStockxAPI" className="value viewValue">
							{usedForStockxAPI ? 'Yes' : 'No'}
						</p>
					</div>
				</div>
			) : (
				<Loading />
			)}

			<div className="form-group form-group-sidebar">
				<button data-cy="modal-close-btn" type="button" className="btn btn-primary-link" onClick={handleClose}>
					<span className="text">Close</span>
				</button>
			</div>
		</SidebarModal>
	);
};
