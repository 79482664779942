import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import Modal from '../../../components/modal/Modal';
import { Col, Row, Wrapper } from '../../../components/content';
import { useAuth } from '../../../context/useAuth';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import Loading from '../../../components/loading/Loading';

const extansionRemoved = (str) => {
	if (str) {
		return str.replace(/.png|.jpg|.jpeg|.gif|.svg/g, '');
	} else {
		console.warn('Problem with string of undefined');
		return;
	}
};

const GalleryImages = (props) => {
	const { data, setRefetch } = props;
	const { user } = useAuth();

	const [toggleImage, setToggleImage] = useState(false);
	const [toggleImageLoader, setToggleImageLoader] = useState(false);
	const [imageId, setImageId] = useState({ id: '' });
	const [imageData, setImageData] = useState({});
	const [imageDataLoading, setImageDataLoading] = useState(false);

	const handleOnToggle = (id) => {
		setImageId({ id: id });
		setToggleImage(true);
	};

	const getImageById = useCallback(
		async (image) => {
			const id = image.id;

			if (id && id.length > 0) {
				setImageDataLoading(true);
				await axios
					.get(`${SERVER_URL}/${AXIOS_API_CALL.getImagesById}/${id}`)
					.then((res) => {
						if (res.status === 200) {
							setImageData(res?.data);
						}
					})
					.catch((err) => {
						console.error(err);
						setImageDataLoading(false);
					})
					.finally(() => {
						setTimeout(() => {
							setImageDataLoading(false);
						}, 700);
					});
			}
		},
		[user]
	);

	useEffect(() => {
		getImageById(imageId);
	}, [imageId]);

	const getDateFormatMDY = (date) => {
		const d = new Date(date);
		const monthAndDay = d.toLocaleString('default', { month: 'long', day: 'numeric' });
		const year = d.getFullYear();

		return `${monthAndDay}, ${year}`;
	};

	const [form, setForm] = useState({
		id: '',
		imageName: '',
		imageAlt: '',
	});

	useEffect(() => {
		if (imageId.id !== '' && Object.keys(imageData).length > 0) {
			const form = {
				id: imageId.id,
				imageName: extansionRemoved(imageData.originalname),
				imageAlt: imageData.altTitle,
			};

			setForm(form);
		}
	}, [imageId, imageData, setForm]);

	const onChange = (event) => {
		const { name, value } = event.target;

		setForm((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		const id = form.id;
		const token = user.token;

		let payload = {
			department: PERMISSIONS.grocery,
			title: form.imageName,
		};

		if (typeof form.imageAlt !== 'undefined' && form.imageAlt !== null) {
			payload = {
				...payload,
				altTitle: form.imageAlt,
			};
		}

		try {
			setToggleImageLoader(true);
			await axios
				.put(`${SERVER_URL}/${AXIOS_API_CALL.updateGalleryImage}/${id}`, { ...payload }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
				.then((res) => {
					if (res.status === 200) {
						setRefetch((p) => !p);
						setToggleImage(false);
						setImageId({ id: '' });
						setImageData({});
						setToggleImageLoader(false);

						// notification
						notification.success({
							message: res.data.message,
							placement: 'bottomLeft',
						});
					}
				})
				.catch((err) => {
					console.error(err);
					setToggleImageLoader(false);
				})
				.finally(() => {
					setToggleImageLoader(false);
				});
		} catch (err) {}
	};

	const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
	const onDelete = async () => {
		const id = form.id;
		const token = user.token;

		try {
			setDeleteBtnLoader(true);
			await axios
				.delete(`${SERVER_URL}/${AXIOS_API_CALL.deleteGalleryImage}/${id}`, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
				.then((res) => {
					if (res.status === 200) {
						setImageId({ id: '' });
						setToggleImage(false);
						setImageData({});
						setRefetch((p) => !p);
						setDeleteBtnLoader(false);

						// notifications
						notification.success({
							message: res.data.message,
							placement: 'bottomLeft',
						});
					}
				})
				.catch((err) => {
					setDeleteBtnLoader(false);
					console.error(err);
				})
				.finally(() => {
					setDeleteBtnLoader(false);
				});
		} catch (err) {}
	};

	return (
		<>
			<Wrapper className="gallery-images">
				<Row>
					{data &&
						data.length > 0 &&
						data.map((item) => {
							return (
								<Col key={item._id}>
									<div className="card" onClick={() => handleOnToggle(item._id)}>
										<img className="img" src={item.localPath} alt={item.originalname} />
									</div>
								</Col>
							);
						})}
				</Row>
			</Wrapper>

			<Modal active={toggleImage} onToggle={setToggleImage} title="Attachment details" className="gallery-view modal-full">
				<div className="view-layout relative">
					{!imageDataLoading ? (
						<>
							<div className="content-image relative">{imageData ? <img src={imageData?.localPath} /> : null}</div>

							<div className="content-info relative">
								<div className="information">
									<p>
										<b>Uploaded on:</b>
										<span> {imageData && imageData?.updatedAt ? getDateFormatMDY(imageData?.updatedAt) : null}</span>
									</p>
									<p>
										<b>File name:</b>
										<span> {imageData && imageData?.originalname ? extansionRemoved(imageData?.originalname) : null}</span>
									</p>
									<p>
										<b>File url:</b>
										<a href={imageData.url}> {imageData && imageData.url}</a>
									</p>
								</div>

								<form
									onSubmit={(event) => {
										onSubmit(event);
									}}
									name="update-image"
									className="form form-update-images"
								>
									<div className="form-group">
										<label className="label" htmlFor="imageName">
											<b>Image name:</b>
										</label>

										<input value={form.imageName || ''} onChange={onChange} name="imageName" id="imageName" className="input input-full" type="text" placeholder="Image Name" />
									</div>

									<div className="form-group">
										<label className="label" htmlFor="imageAlt">
											<b>Image alt:</b>
										</label>

										<input value={form.imageAlt || ''} onChange={onChange} name="imageAlt" id="imageAlt" className="input input-full" type="text" placeholder="Image Alt" />
									</div>

									<div className="form-group">
										<div className="actions">
											{toggleImageLoader || deleteBtnLoader ? (
												<button className="btn btn-primary pl-2 pr-2" type="button">
													<span className="text">Saving...</span>
												</button>
											) : (
												<button className="btn btn-primary pl-2 pr-2" type="submit">
													<span className="text">Save changes</span>
												</button>
											)}
										</div>
									</div>
								</form>

								<div className="information">
									<div className="actions">
										{toggleImageLoader || deleteBtnLoader ? (
											<button type="button" className="btn btn-danger">
												<span className="text">Loading Delete...</span>
											</button>
										) : (
											<button onClick={() => onDelete()} type="button" className="btn btn-danger">
												<span className="text">Delete permanently</span>
											</button>
										)}
									</div>
								</div>
							</div>
						</>
					) : (
						<Loading />
					)}
				</div>
			</Modal>
		</>
	);
};

export default GalleryImages;
